import React from 'react';
import { Paper, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const ElevatedPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  width: '100%',
  elevation: 10
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -30,
  left: 30,
  width: 80,
  height: 80,
  zIndex: 99999,
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.shadows[3],
}));

const SmallCard = ({ icon: Icon, title, value, textColor,className }) => {
  return (
    <Box position="relative" display="inline-block" p={1} width="100%" mt={5} sx={{borderRadius:"23px !important"}}>
      <IconWrapper className={className}>
        <Icon sx={{ fontSize: 30, color: 'white' }} />
      </IconWrapper>
      <ElevatedPaper sx={{borderRadius:"23px !important"}}>
        <CardContent sx={{marginTop:"15px",paddingBottom:"10px !important"}}>
          <Typography color="black" gutterBottom fontWeight={800}>
            {title}
          </Typography>
          <Typography variant="h5" component="div" sx={{ color: textColor ,fontWeight:"400"}}>
            {value}
          </Typography>
        </CardContent>
      </ElevatedPaper>
    </Box>
  );
};

export default SmallCard;
