import React from "react";
import { Grid, Paper } from "@mui/material";

import AdminApiKeyGeneration from "./AdminApiKeyGeneration";
import AdminApiDoc from "./AdminApiDoc";

const AdminApiIntegration = () => {
  return (
    <>
      <Grid container fluid style={{ padding: "16px" }}>
        <Paper elevation={3} style={{ padding: "20px", width: "100%" }}>
          <AdminApiKeyGeneration />
          <AdminApiDoc />
        </Paper>
      </Grid>
    </>
  );
};

export default AdminApiIntegration;
