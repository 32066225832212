import React from "react";
import Purchase from "../../MasterPurchase/Purchase";

const AdminPurchase = ({ role }) => {
  const uid = sessionStorage.getItem("userUid");
  return (
    <div>
      <Purchase role={role} uid={uid} />
    </div>
  );
};

export default AdminPurchase;
