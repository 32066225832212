import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { HOST } from "../../../config";

const SwaggerComponent = () => {
  const spec = {
    swagger: "2.0",
    info: {
      title: "API Documentation",
      description: "API documentation for the application",
      version: "1.0.0",
    },
    host: HOST,
    basePath: '/apiv1',
    schemes: ["https","http"],
    paths: {
      "/links/create_shorturl": {
        post: {
          summary: "Create Basic Shorten URL",
          tags: ["Links"],
          description: "Pass the necessary values to create a Basic shortened URL.",
          parameters: [
            {
              name: "api_key",
              in: "query",
              description: "API key for authentication",
              required: true,
              type: "string",
            },
            {
              name: "api_secret",
              in: "query",
              description: "API secret for authentication",
              required: true,
              type: "string",
            },
            {
              name: "unique_signature",
              in: "query",
              description: "Unique signature of the user",
              required: true,
              type: "string",
            },
            {
              name: "url",
              in: "query",
              description: "The URL to shorten",
              required: true,
              type: "string",
            },
          ],
          responses: {
            200: {
              description: "Link created successfully",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Link created successfully",
                  },
                  data: {
                    type: "object",
                    properties: {
                      link: {
                        type: "string",
                        example: "https://api.example.com/shortlink/123456",
                      },
                    },
                  },
                },
              },
            },
            400: {
              description: "Bad Request",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Required fields missing: api_key, api_secret, unique_signature, url",
                  },
                },
              },
            },
            401: {
              description: "Unauthorized - Invalid API Key or Secret",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Invalid API Key or Secret.",
                  },
                },
              },
            },
            409: {
              description: "Conflict - Duplicate Link",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Link already exists.",
                  },
                  data: {
                    type: "string",
                    example: "https://api.example.com/shortlink/123456",
                  },
                },
              },
            },
            500: {
              description: "Internal Server Error",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Internal server error.",
                  },
                },
              },
            },
          },
        },
      },
      "/user/get_credits": {
        get: {
          summary: "Get User Credits Balance",
          tags: ["Users"],
          description: "Retrieve the credit balance for a user.",
          parameters: [
            {
              name: "api_key",
              in: "query",
              description: "API key for authentication",
              required: true,
              type: "string",
            },
            {
              name: "api_secret",
              in: "query",
              description: "API secret for authentication",
              required: true,
              type: "string",
            },
            {
              name: "unique_signature",
              in: "query",
              description: "Unique signature of the user",
              required: true,
              type: "string",
            },
          ],
          responses: {
            200: {
              description: "Credits retrieved successfully",
              schema: {
                type: "object",
                properties: {
                  link_credits: {
                    type: "number",
                    example: 50,
                  },
                  qr_credits: {
                    type: "number",
                    example: 25,
                  },
                },
              },
            },
            400: {
              description: "Bad Request",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Required fields missing: api_key, api_secret, unique_signature",
                  },
                },
              },
            },
            401: {
              description: "Unauthorized - Invalid API Key or Secret",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Invalid API Key or Secret.",
                  },
                },
              },
            },
            404: {
              description: "User not found",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "User not found.",
                  },
                },
              },
            },
            500: {
              description: "Internal Server Error",
              schema: {
                type: "object",
                properties: {
                  message: {
                    type: "string",
                    example: "Internal server error.",
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return <SwaggerUI spec={spec} />;
};

export default SwaggerComponent;
