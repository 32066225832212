import React, { useState } from "react";
import ChatBox from "./ChatBox";
import { Fab, Zoom, Tooltip } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import io from "socket.io-client";
import { SOCKET_URL } from "../../config";

const socket = io(SOCKET_URL, {
  path: "/api/user/v1/live_chat",
});
function ChatButton() {
  const [openChat, setOpenChat] = useState(false);
  const [ticket, Setopenticket] = useState(false);

  const user_name = sessionStorage.getItem("UserName");
  const email_id = sessionStorage.getItem("email_id");
  const user_uid = sessionStorage.getItem("userUid");

  const handleOpenChat = () => {
    setOpenChat(true);
    socket.emit('fetchUserTickets', data.user_uid);
    socket.on("userTickets", (openTickets) => {
      if (openTickets.length > 0) {
        Setopenticket(openTickets)
      }
    });
    return () => {
      socket.off("userTickets");
    };
  };
  

  const handleCloseChat = () => {
    setOpenChat(false);
  };



  const data = {
    user_name: user_name,
    email_id: email_id,
    user_uid: user_uid,
  };

  return (
    <>
      <Zoom in={!openChat} unmountOnExit>
        <Tooltip title="Live Support">
          <Fab
            className="blacky"
            onClick={handleOpenChat}
            style={{ position: "fixed", bottom: 20, right: 20 }}
          >
            <ChatIcon sx={{ color: "white" }} />
          </Fab>
        </Tooltip>
      </Zoom>
      {openChat && <ChatBox onClose={handleCloseChat} data={data}  openTicket={ticket}/>}
    </>
  );
}

export default ChatButton;
