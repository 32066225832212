import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  MenuItem,
  Grid,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";
import Controller from "../../../Service/ApiController";
import { apiEndPoint } from "../../../Service/ApiConstant";
import { useSnackbar } from "notistack";

const validationSchema = Yup.object({
  planType: Yup.string().required("Plan Type is required"),
  basicDetails: Yup.object({
    title: Yup.string().required("Title is required"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    colors: Yup.string().required("Colors are required"),
    details: Yup.array()
      .of(
        Yup.string()
          .min(10, "Each detail must be at least 10 characters")
          .max(40, "Detail must be less than 40 characters")
      )
      .max(9, "Cannot add more than 9 details"),
    icon: Yup.string().required("Icon is required"),
    planValidity: Yup.number()
      .required("Plan Validity is required")
      .positive("Must be positive"),
    planValidityUnit: Yup.string().required("Plan Validity Unit is required"),
  }),
  creditSettings: Yup.object({
    planCredits: Yup.number()
      .required("Plan Credits are required")
      .min(0, "Plan Validity must be at least 0")
      .test(
        "positive-or-zero",
        "Must be positive",
        (value) => value === 0 || value > 0
      ),
    qrCredits: Yup.number()
      .required("QR Credits are required")
      .min(0, "QR Validity must be at least 0")
      .test(
        "positive-or-zero",
        "Must be positive",
        (value) => value === 0 || value > 0
      ),
    dataRetentionValidity: Yup.number()
      .required("Data Retention Validity is required")
      .positive("Must be positive"),
    dataRetentionUnit: Yup.string().required("Data Retention Unit is required"),
  }),
});

const SuperAdminPurchase = () => {
  const user_uid = sessionStorage.getItem("userUid");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [totalPlans, setTotalPlans] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [currentDetail, setCurrentDetail] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchPlan = async (page = 1, size = 5) => {
    try {
      const result = await Controller.ApiController(
        "",
        `${apiEndPoint.PLAN_FETCHER}?page=${page}&pageSize=${size}`,
        "GET"
      );
      if (result.success === true) {
        setPlanData(result.data.plans);
        setTotalPlans(result.data.totalPlans);
        setCurrentPage(result.data.currentPage);
      } else {
        enqueueSnackbar(result.data, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  useEffect(() => {
    fetchPlan(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const formik = useFormik({
    initialValues: {
      planType: "",
      basicDetails: {
        title: "",
        price: "",
        colors: "",
        details: [],
        icon: "",
        planValidity: "",
        planValidityUnit: "days",
      },
      creditSettings: {
        planCredits: "",
        qrCredits: "",
        dataRetentionValidity: "",
        dataRetentionUnit: "days",
      },
      mainSettings: {
        isFolder: false,
        isPermanent: false,
        isRegionRestriction: false,
        isCountryRestriction: false,
        isPasswordRestriction: false,
        isCustomDomain:false,
      },
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = { ...values, user_uid: user_uid };
      const endpoint = editMode
        ? apiEndPoint.PLAN_UPDATE
        : apiEndPoint.PLAN_CREATION;
      const method = editMode ? "PUT" : "POST";
      try {
        const response = await Controller.ApiController(data, endpoint, method);
        if (response.success === true) {
          enqueueSnackbar(response.data.message, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          setOpen(false);
          resetForm();
          setEditMode(false);
          fetchPlan(currentPage, pageSize);
        } else {
          enqueueSnackbar(response.data, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        const data = error.response.data.error || error.response.data.message;
        enqueueSnackbar(data, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  const handleOpen = (plan = null) => {
    if (plan) {
      setEditMode(true);
      setCurrentPlan(plan);
      formik.setValues(plan);
    } else {
      setEditMode(false);
      formik.resetForm();
    }
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setEditMode(false);
  };

  const handleDetailModalOpen = (index = null) => {
    if (index !== null) {
      setCurrentDetail(formik.values.basicDetails.details[index]);
      setEditingIndex(index);
    } else {
      setCurrentDetail("");
      setEditingIndex(null);
    }
    setDetailModalOpen(true);
  };

  const handleDetailModalClose = () => setDetailModalOpen(false);

  const addOrUpdateDetail = () => {
    if (currentDetail.trim() && formik.values.basicDetails.details.length < 9) {
      const updatedDetails = [...formik.values.basicDetails.details];
      if (editingIndex !== null) {
        updatedDetails[editingIndex] = currentDetail;
      } else {
        updatedDetails.push(currentDetail);
      }
      formik.setFieldValue("basicDetails.details", updatedDetails);
      setCurrentDetail("");
      handleDetailModalClose();
    }
  };

  const removeDetail = (index) => {
    const updatedDetails = formik.values.basicDetails.details.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("basicDetails.details", updatedDetails);
  };

  const handleDelete = async (planId) => {
    const plan_id = planId
    const uid = user_uid
    try {
      const response = await Controller.ApiController(
        "",
        `${apiEndPoint.PLAN_DELETER}/${plan_id}/${uid}`,
        "DELETE"
      );
      if (response.success === true) {
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchPlan(currentPage, pageSize);
      } else {
        enqueueSnackbar(response.data, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      const data = error.response.data.error || error.response.data.message;
      enqueueSnackbar(data, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Box>
      <Typography variant="h4" textAlign={"center"}>
        Super Admin Plan Management
      </Typography>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
          className="greeny"
        >
          Add Plan
        </Button>
      </Grid>
      <TableContainer component={Paper} sx={{ mt: "15px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:"900"}}>S.No</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Plan Type</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Title</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Price</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Plan Validity</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Created By</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Last Modification By</TableCell>
              <TableCell sx={{fontWeight:"900"}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planData.map((plan, index) => (
              <TableRow key={plan._id}  sx={{
                backgroundColor:
                  index % 2 === 0 ? "white" : "grey.200",
              }}
            >
                <TableCell sx={{fontWeight:"900"}}>
                  {(currentPage - 1) * pageSize + index + 1}
                </TableCell>
                <TableCell>{plan.planType}</TableCell>
                <TableCell>{plan.basicDetails.title}</TableCell>
                <TableCell>{plan.basicDetails.price} AUD</TableCell>
                <TableCell>
                  {plan.basicDetails.planValidity}{" "}
                  {plan.basicDetails.planValidityUnit}
                </TableCell>
                <TableCell>
                  {plan.created_by ? plan.created_by : "-"}
                </TableCell>
                <TableCell>
                  {plan.updated_by? plan.updated_by : "-"}
                </TableCell>

                <TableCell>
                  <IconButton className="orangy" onClick={() => handleOpen(plan)}>
                    <EditIcon  sx={{color:"white"}}/>
                  </IconButton>
                  <IconButton  sx={{marginLeft:"5px"}} className="pinky"  onClick={() => handleDelete(plan.plan_id)}>
                    <DeleteIcon  sx={{color:"white"}}  />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalPlans}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography variant="h6">
            {editMode ? "Edit Plan" : "Add New Plan"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle1" gutterBottom>
                Plan Type
              </Typography>
              <RadioGroup
                value={formik.values.planType}
                onChange={formik.handleChange}
                name="planType"
                row
              >
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="Monthly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio />}
                  label="Yearly"
                />
                <FormControlLabel
                  value="addon"
                  control={<Radio />}
                  label="Add-on"
                />
                <FormControlLabel
                  value="offers"
                  control={<Radio />}
                  label="Offers"
                />
              </RadioGroup>
              {formik.touched.planType && formik.errors.planType && (
                <Typography color="error">{formik.errors.planType}</Typography>
              )}
            </FormControl>
            <Accordion sx={{ borderRadius: "10px" }}>
              <AccordionSummary
                className="bluey"
                sx={{ borderRadius: "10px" }}
                expandIcon={
                  formik.errors.basicDetails ? (
                    <ErrorIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography style={{ color: "white" }}>
                  Basic Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Title"
                      name="basicDetails.title"
                      value={formik.values.basicDetails.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.basicDetails?.title &&
                        Boolean(formik.errors.basicDetails?.title)
                      }
                      helperText={
                        formik.touched.basicDetails?.title &&
                        formik.errors.basicDetails?.title
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Price"
                      name="basicDetails.price"
                      type="number"
                      value={formik.values.basicDetails.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.basicDetails?.price &&
                        Boolean(formik.errors.basicDetails?.price)
                      }
                      helperText={
                        formik.touched.basicDetails?.price &&
                        formik.errors.basicDetails?.price
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Colors (comma separated)"
                      name="basicDetails.colors"
                      value={formik.values.basicDetails.colors}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.basicDetails?.colors &&
                        Boolean(formik.errors.basicDetails?.colors)
                      }
                      helperText={
                        formik.touched.basicDetails?.colors &&
                        formik.errors.basicDetails?.colors
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <TextField
                          label="Details"
                          name="basicDetails.details"
                          value={formik.values.basicDetails.details.join(", ")}
                          onChange={() => {}}
                          fullWidth
                          size="small"
                          error={
                            formik.touched.basicDetails?.details &&
                            Boolean(formik.errors.basicDetails?.details)
                          }
                          helperText={
                            formik.touched.basicDetails?.details &&
                            formik.errors.basicDetails?.details
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          ml={1}
                        >
                          <IconButton
                            onClick={() => handleDetailModalOpen()}
                            size="small"
                            className="greeny"
                          >
                            <AddIcon sx={{ color: "white" }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      {formik.values.basicDetails.details.map(
                        (detail, index) => (
                          <Chip
                            key={index}
                            label={detail}
                            onDelete={() => removeDetail(index)}
                            onClick={() => handleDetailModalOpen(index)}
                            deleteIcon={
                              <CloseIcon
                                sx={{ color: "white", fontSize: 14 }}
                              />
                            }
                            sx={{
                              marginRight: 1,
                              marginBottom: 1,
                              fontSize: "0.75rem", // smaller text size
                              color: "white", // optional: text color for chip
                              borderColor: "primary.main", // optional: border color for chip
                            }}
                            className="bluey"
                            variant="outlined"
                            icon={
                              <EditIcon sx={{ color: "white", fontSize: 14 }} />
                            }
                          />
                        )
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Icon"
                      name="basicDetails.icon"
                      value={formik.values.basicDetails.icon}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.basicDetails?.icon &&
                        Boolean(formik.errors.basicDetails?.icon)
                      }
                      helperText={
                        formik.touched.basicDetails?.icon &&
                        formik.errors.basicDetails?.icon
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={8} mt={1}>
                        <TextField
                          label="Plan Validity"
                          name="basicDetails.planValidity"
                          type="number"
                          value={formik.values.basicDetails.planValidity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          size="small"
                          error={
                            formik.touched.basicDetails?.planValidity &&
                            Boolean(formik.errors.basicDetails?.planValidity)
                          }
                          helperText={
                            formik.touched.basicDetails?.planValidity &&
                            formik.errors.basicDetails?.planValidity
                          }
                        />
                      </Grid>
                      <Grid item xs={3.8} ml={1}>
                        <TextField
                          select
                          label="Unit"
                          name="basicDetails.planValidityUnit"
                          value={formik.values.basicDetails.planValidityUnit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          size="small"
                          margin="normal"
                        >
                          <MenuItem value="days">Days</MenuItem>
                          <MenuItem value="months">Months</MenuItem>
                          <MenuItem value="years">Years</MenuItem>
                          <MenuItem value="offers">Years</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ borderRadius: "10px", marginTop: "6px" }}>
              <AccordionSummary
                className="bluey"
                sx={{ borderRadius: "10px" }}
                expandIcon={
                  formik.errors.creditSettings ? (
                    <ErrorIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography sx={{ color: "white" }}>Credit Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Plan Credits"
                      name="creditSettings.planCredits"
                      type="number"
                      value={formik.values.creditSettings.planCredits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.creditSettings?.planCredits &&
                        Boolean(formik.errors.creditSettings?.planCredits)
                      }
                      helperText={
                        formik.touched.creditSettings?.planCredits &&
                        formik.errors.creditSettings?.planCredits
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="QR Credits"
                      name="creditSettings.qrCredits"
                      type="number"
                      value={formik.values.creditSettings.qrCredits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.creditSettings?.qrCredits &&
                        Boolean(formik.errors.creditSettings?.qrCredits)
                      }
                      helperText={
                        formik.touched.creditSettings?.qrCredits &&
                        formik.errors.creditSettings?.qrCredits
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={8} mt={1}>
                        <TextField
                          label="Data Retention Validity"
                          name="creditSettings.dataRetentionValidity"
                          type="number"
                          value={
                            formik.values.creditSettings.dataRetentionValidity
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          size="small"
                          error={
                            formik.touched.creditSettings
                              ?.dataRetentionValidity &&
                            Boolean(
                              formik.errors.creditSettings
                                ?.dataRetentionValidity
                            )
                          }
                          helperText={
                            formik.touched.creditSettings
                              ?.dataRetentionValidity &&
                            formik.errors.creditSettings?.dataRetentionValidity
                          }
                        />
                      </Grid>
                      <Grid item xs={3.8} ml={1}>
                        <TextField
                          select
                          label="Unit"
                          name="creditSettings.dataRetentionUnit"
                          value={formik.values.creditSettings.dataRetentionUnit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          size="small"
                          margin="normal"
                        >
                          <MenuItem value="days">Days</MenuItem>
                          <MenuItem value="months">Months</MenuItem>
                          <MenuItem value="years">Years</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ borderRadius: "10px", marginTop: "6px" }}>
              <AccordionSummary
                className="bluey"
                sx={{ borderRadius: "10px" }}
                expandIcon={
                  formik.errors.mainSettings ? (
                    <ErrorIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography sx={{ color: "white" }}>Main Settings</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <FormControl component="fieldset" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isFolder"
                        checked={formik.values.mainSettings.isFolder}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Folder Creation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isPermanent"
                        checked={formik.values.mainSettings.isPermanent}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Custom Expiration"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isRegionRestriction"
                        checked={formik.values.mainSettings.isRegionRestriction}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Region Restriction"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isCountryRestriction"
                        checked={formik.values.mainSettings.isCountryRestriction}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Country Restriction"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isPasswordRestriction"
                        checked={
                          formik.values.mainSettings.isPasswordRestriction
                        }
                        onChange={formik.handleChange}
                      />
                    }
                    label="Password Protection"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="mainSettings.isCustomDomain"
                        checked={
                          formik.values.mainSettings.isCustomDomain
                        }
                        onChange={formik.handleChange}
                      />
                    }
                    label="Custom Domain"
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="greeny"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                className="redy"
                onClick={handleClose}
                sx={{ ml: 2, color: "white" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal open={detailModalOpen} onClose={handleDetailModalClose}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            maxWidth: 400,
            margin: "auto",
            mt: 5,
          }}
        >
          <Typography variant="h6">
            {editingIndex !== null ? "Edit Detail" : "Add Detail"}
          </Typography>
          <TextField
            fullWidth
            label="Detail"
            value={currentDetail}
            onChange={(e) => setCurrentDetail(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addOrUpdateDetail}
            sx={{ mt: 2 }}
          >
            {editingIndex !== null ? "Update Detail" : "Add Detail"}
          </Button>
          <Button
            variant="outlined" 
            color="secondary"
            onClick={handleDetailModalClose}
            sx={{ mt: 2, ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SuperAdminPurchase;
