import React from "react";
import Dashboard from "../../MasterDashboard/Dashboard";

const SuperAdminDashboard = () => {
  const uid = sessionStorage.getItem("userUid");
  return (
    <div>
      <Dashboard uid={uid} />
    </div>
  );
};

export default SuperAdminDashboard;
