import React, { useState, useCallback } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "notistack";

const DownloadButton = ({ attachment, socket }) => {
  const [attachments, setAttachments] = useState({});
  const [downloadingAttachments, setDownloadingAttachments] = useState({});
  const [buttonStates, setButtonStates] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleFileAction = useCallback(
    async (attachment, action) => {
      if (action === "download") {
        setDownloadingAttachments((prev) => ({
          ...prev,
          [attachment.path]: true,
        }));
        setButtonStates((prev) => ({
          ...prev,
          [attachment.path]: "orangy",
        }));

        try {
          let attachmentData = attachments[attachment.path];
          if (!attachmentData) {
            await new Promise((resolve) => {
              socket.emit("getAttachment", { path: attachment.path });
              socket.once("attachmentData", ({ path, data }) => {
                setAttachments((prev) => ({ ...prev, [path]: data }));
                attachmentData = data;
                resolve();
              });
            });
          }

          const link = document.createElement("a");
          link.href = `data:${attachment.contentType};base64,${attachmentData}`;
          link.download = attachment.filename;
          link.click();

          setButtonStates((prev) => ({
            ...prev,
            [attachment.path]: "greeny",
          }));
          setTimeout(() => {
            setButtonStates((prev) => ({
              ...prev,
              [attachment.path]: "violety",
            }));
          }, 5000);
        } catch (error) {
          enqueueSnackbar("Failed to download file", { variant: "error" });
        } finally {
          setDownloadingAttachments((prev) => ({
            ...prev,
            [attachment.path]: false,
          }));
        }
      }
    },
    [attachments, enqueueSnackbar, socket]
  );

  return (
    <IconButton
      onClick={() => handleFileAction(attachment, "download")}
      disabled={downloadingAttachments[attachment.path]}
      className={buttonStates[attachment.path] || "violety"}
      sx={{
        position: "relative",
        width: "36px",
        height: "36px",
        color: "white",
        "&:hover": {
          backgroundColor: downloadingAttachments[attachment.path]
            ? "orangered"
            : buttonStates[attachment.path] === "greeny"
            ? "green"
            : "blue",
        },
      }}
    >
      {downloadingAttachments[attachment.path] ? (
        <CircularProgress
          size={23}
          thickness={4.5} // You can adjust this thickness for better appearance
          sx={{
            color: "white",
            position: "absolute", // Ensure it's absolutely positioned
            top: "50%",
            left: "50%",
            marginTop: "-11.5px", // Half of size to center the spinner
            marginLeft: "-11.5px",
          }}
        />
      ) : buttonStates[attachment.path] === "greeny" ? (
        <CheckIcon
          sx={{
            fontSize: 23,
            color: "white",
            fontWeight: "bold",
          }}
        />
      ) : (
        <GetAppIcon sx={{ fontSize: 23 }} />
      )}
    </IconButton>
  );
};

export default DownloadButton;
