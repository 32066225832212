import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { BASE_URL } from "../../config";
import { useSnackbar } from "notistack";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

const FolderManagement = ({ uid, dataFetchers }) => {
  const [folders, setFolders] = useState([]);
  const [open, setOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [folderToDelete, setFolderToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchFolders = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/folders/get_folder/${uid}`);
      setFolders(response.data);
    } catch (error) {
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleAddFolder = async () => {
    if (newFolderName.length > 20) {
      enqueueSnackbar(`Size Should Not exceed 20 Characters`, {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    try {
      const result = await axios.post(`${BASE_URL}/folders/add_folder/${uid}`, {
        folderName: newFolderName,
      });
      setNewFolderName("");
      fetchFolders();
      dataFetchers();
      enqueueSnackbar(`${result.data.message}`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleDeleteFolder = async (folderName) => {
    try {
      const result = await axios.delete(
        `${BASE_URL}/folders/delete_folder/${uid}`,
        {
          data: { folderName },
        }
      );
      setFolderToDelete(null);
      fetchFolders();
      dataFetchers();
      enqueueSnackbar(`${result.data.message}`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const confirmDeleteFolder = (folderName) => {
    setFolderToDelete(folderName);
  };

  const handleConfirmDelete = () => {
    if (folderToDelete) {
      handleDeleteFolder(folderToDelete);
    }
  };

  useEffect(() => {
    if (open) {
      fetchFolders();
    }
  }, [open]);

  return (
    <div>
      <Tooltip title="Manage Folders">
        <IconButton onClick={() => setOpen(true)} className="bluey">
          <CreateNewFolderIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setNewFolderName("");
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ fontWeight: "900" }}>Manage Folders</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "900" }}>Folder Name</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder="New Folder Name"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleAddFolder} className="greeny">
                    <AddIcon sx={{ color: "white" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
              {folders.map((folder, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "600 !important" }}>
                    {folder.folder_name}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => confirmDeleteFolder(folder.folder_name)}
                      className="redy"
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setNewFolderName("");
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!folderToDelete}
        onClose={() => setFolderToDelete(null)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the folder "{folderToDelete}"?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setFolderToDelete(null)}
            className="orangy"
            sx={{ color: "white" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className="redy"
            sx={{ color: "white" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FolderManagement;
