import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import io from "socket.io-client";
import Loader from "../../Modals/Loader";
import { apiEndPoint } from "../../../Service/ApiConstant";
import Controller from "../../../Service/ApiController";
import { useSnackbar } from "notistack";
import { SOCKET_URL } from "../../../config";

const socket = io(SOCKET_URL, {
  path: "/api/user/v1/live_chat",
});

const ITEMS_PER_PAGE = 5;

const AssignTicketsTab = ({ role, supportUserRole }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [assignCurrentTicket, setAssignCurrentTicket] = useState(null);
  const [currentPageTickets, setCurrentPageTickets] = useState(1);

  const fetchUsers = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.SUPPORT_GET,
        "GET"
      );
      if (result.success === true) {
        setUsers(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin Recharge", error);
    }
  };

  useEffect(() => {
    socket.emit("fetchTickets");
    socket.on("ticketsData", (data) => setTickets(data));
    socket.on("ticketListUpdated", () => {
      socket.emit("fetchTickets");
    });
    fetchUsers();
  }, []);

  const handleAssignTicket = () => setOpenModal(true);

  const handleModalClose = () => setOpenModal(false);

  const handleAssign = () => {
    if (selectedUserEmail) {
      socket.emit("assignTicket", {
        ticket_id: assignCurrentTicket.ticket_id,
        assigned_user: selectedUserEmail,
      });
      setOpenModal(false);
      setSelectedUserEmail("");
    } else {
      enqueueSnackbar(`Please select a user to assign the ticket.`, {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handlePageChangeTickets = (event, value) =>
    setCurrentPageTickets(value);

  const paginatedTickets = tickets
    .filter((ticket) => ticket.status !== "closed" && !ticket.assigned_user)
    .slice(
      (currentPageTickets - 1) * ITEMS_PER_PAGE,
      currentPageTickets * ITEMS_PER_PAGE
    );

  return (
    <Box sx={{ padding: 2 }}>
      <Loader timing={2000} />
      {paginatedTickets.length === 0 ? (
        <Typography textAlign={"center"} fontWeight={800} mt={5}>
          No pending tickets to be assigned.
        </Typography>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  SNO
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Customer Name
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Customer Email
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Query
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTickets.map((ticket, index) => (
                <TableRow key={ticket.ticket_id} sx={{
                  backgroundColor:
                    index % 2 === 0 ? "white" : "grey.200",
                }}>
                  <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                    {(currentPageTickets - 1) * ITEMS_PER_PAGE + index + 1}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {ticket.user_name || "Unknown"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {ticket.email_id || "Unknown"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {ticket.query}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {ticket.status}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {(role.includes("Support_Super_Admin") ||
                      supportUserRole === "Support_Admin") && (
                      <Button
                        className="greeny"
                        variant="contained"
                        onClick={() => {
                          handleAssignTicket();
                          setAssignCurrentTicket(ticket);
                        }}
                      >
                        Assign
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Pagination
              count={Math.ceil(
                tickets.filter(
                  (ticket) =>
                    ticket.status !== "closed" && !ticket.assigned_user
                ).length / ITEMS_PER_PAGE
              )}
              page={currentPageTickets}
              onChange={handlePageChangeTickets}
            />
          </Box>
        </>
      )}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            width: 400,
            padding: 3,
            borderRadius: 2,
            backgroundColor: "white",
            boxShadow: 3,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6" mb={2} textAlign={"center"}>
            Assign Ticket
          </Typography>
          <FormControl fullWidth>
            <InputLabel>User</InputLabel>
            <Select
              value={selectedUserEmail}
              onChange={(e) => setSelectedUserEmail(e.target.value)}
              label="User"
            >
              {users.length === 0 ? (
                <MenuItem value="">No users available</MenuItem>
              ) : (
                users.map((user) => (
                  <MenuItem key={user.email} value={user.email}>
                    {user.email}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <Box
            sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleModalClose}
              sx={{ marginRight: 1 }}
              variant="contained"
              className="redy"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAssign}
              variant="contained"
              className="greeny"
            >
              Assign
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AssignTicketsTab;
