import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
  Button,
  Container,
} from "@mui/material";
import MasterDomain from "../../MasterDomain/MasterDomain";

import { useSnackbar } from "notistack";
import { apiEndPoint } from "../../../Service/ApiConstant";
import Controller from "../../../Service/ApiController";

const SuperAdminDomain = ({role}) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.GET_ALL_USERS + page,
        "GET"
      );
      if (result.success === true) {
        setUsers(result.data.users);
        setTotalPages(result.data.totalPages);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin Recharge", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleViewCustomer = (customer) => {
    setSelectedCustomer(customer);
  };

  const handleBackToList = () => {
    setSelectedCustomer(null);
  };

  return (
    <>
      <Grid style={{ textAlign: "center" }}>
        <h3>Customer Domain Management</h3>
      </Grid>
      {!selectedCustomer ? (
        <Grid style={{ marginTop: "20px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>S.no</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>User Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Company Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Current Plan
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((customer, index) => (
                  <TableRow key={customer.uid}>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {(currentPage - 1) * 5 + index + 1}
                    </TableCell>
                    <TableCell>{customer.email_id}</TableCell>
                    <TableCell>{customer.user_name}</TableCell>
                    <TableCell>{customer.company_name}</TableCell>
                    <TableCell>{customer.plan_details.plan_name}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleViewCustomer(customer)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Grid>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <CustomerDetails
          customer={selectedCustomer}
          onBack={handleBackToList}
          role={role}
        />
      )}
    </>
  );
};

const CustomerDetails = ({ customer, onBack,role }) => {
  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop:"26px"
        }}
      >
        <Button variant="contained" className="bluey" onClick={onBack}>
          Back to List
        </Button>
      </Grid>

      <div style={{ marginTop: "10px" }}>
        <MasterDomain role={role} uid={customer.user_uid}  />
      </div>
    </Grid>
  );
};

export default SuperAdminDomain;
