import React, { useState, useEffect } from "react";
import Controller from "../../Service/ApiController";
import { apiEndPoint } from "../../Service/ApiConstant";
import { useSnackbar } from "notistack";
import { Grid, Box, Typography, Paper, IconButton } from "@mui/material";
import SmallStatisticsCard from "../Cards/SmallStatisticsCard";
import { Line, Bar } from "react-chartjs-2";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LinkIcon from "@mui/icons-material/Link";
import ReactDOMServer from "react-dom/server";
import EventIcon from "@mui/icons-material/Event";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ uid }) => {
  const [userdata, setUserdata] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [dashboardChartData, setDashboardChartData] = useState();
  const [countdown, setCountdown] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + uid,
        "GET"
      );
      if (result.success === true) {
        setUserdata(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  const fetchDashboardData = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.DASHBOARD_DATA + uid,
        "GET"
      );
      if (result.success === true) {
        setDashboardData(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  const fetchDashboardChartData = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.DASHBOARD_CHART_DATA + uid,
        "GET"
      );
      if (result.success === true) {
        setDashboardChartData(result.data);
      } else {
        enqueueSnackbar(`Error Fetching Chart Data`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchDashboardData();
    fetchDashboardChartData();
  }, [uid]);

  useEffect(() => {
    if (
      userdata &&
      userdata.plan_details &&
      userdata.plan_details.plan_validity_date &&
      userdata.company_level_permissions.includes("admin")
    ) {
      const calculateTimeLeft = () => {
        const now = new Date();
        const endDate = new Date(userdata.plan_details.plan_validity_date);
        const timeLeft = endDate - now;

        if (timeLeft <= 0) {
          setCountdown("Expired");
        } else {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      };

      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timer);
    } else {
      setCountdown("Unlimited");
    }
  }, [userdata]);

  const cardData = [
    {
      icon: TrendingUpIcon,
      title: "Link Credits",
      value: dashboardData ? dashboardData.link_credits : "Loading...",
      textColor: "#000000",
      className: "pinky",
    },
    {
      icon: CreditCardIcon,
      title: "QR Credits",
      value: dashboardData ? dashboardData.qr_credits : "Loading...",
      textColor: "#000000",
      className: "greeny",
    },
    {
      icon: LinkIcon,
      title: "Total Active Links",
      value: dashboardData ? dashboardData.link_count : "Loading...",
      textColor: "#000000",
      className: "orangy",
    },
    {
      icon: EventIcon,
      title: "Plan Expires",
      value: countdown,
      textColor: "#000000",
      className: "bluey",
    },
  ];

  const lineChartData = {
    labels: dashboardChartData?.lineChartData?.labels || [],
    datasets: [
      {
        label: "Links Created - Current Year",
        data: dashboardChartData?.lineChartData?.datasets.find(dataset => dataset.label.includes("Current Year"))?.data || [],
        fill: false,
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Links Created - Previous Year",
        data: dashboardChartData?.lineChartData?.datasets.find(dataset => dataset.label.includes("Previous Year"))?.data || [],
        fill: false,
        borderColor: "rgba(255,99,132,1)"
      }
    ]
  };

  const barChartData = {
    labels: dashboardChartData?.barChartData?.labels || [],
    datasets: [
      {
        label: "Link Clicks - Current Year",
        data: dashboardChartData?.barChartData?.datasets.find(dataset => dataset.label.includes("Current Year"))?.data || [],
        backgroundColor: "rgba(75,192,192,0.2)"
      },
      {
        label: "Link Clicks - Previous Year",
        data: dashboardChartData?.barChartData?.datasets.find(dataset => dataset.label.includes("Previous Year"))?.data || [],
        backgroundColor: "rgba(255,99,132,0.2)"
      }
    ]
  };
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "30px", fontWeight: "800" }}>
          DASHBOARD
        </Typography>
      </Grid>
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box mb={1.5}>
            <SmallStatisticsCard
              icon={card.icon}
              title={card.title}
              value={card.value}
              textColor={card.textColor}
              className={card.className}
            />
          </Box>
        </Grid>
      ))}

      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={2} mb={3}>
            <Line data={lineChartData} />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={2} mb={3}>
            <Bar data={barChartData} />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box p={2} mb={3}>
            <MapContainer
              center={[20, 0]}
              zoom={2}
              style={{ height: "500px", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {dashboardData &&
                dashboardData.trafficData &&
                dashboardData.trafficData.map((item, index) =>
                  item.latitude && item.longitude ? (
                    <Marker
                      key={index}
                      position={[item.latitude, item.longitude]}
                      icon={L.divIcon({
                        className: "custom-icon",
                        html: ReactDOMServer.renderToString(
                          <IconButton
                            size="medium"
                            style={{
                              backgroundColor: "transparent",
                              padding: 0,
                              width: "30px",
                              border: "0px solid",
                            }}
                          >
                            <LocationOnIcon
                              style={{ fontSize: 25, color: "black" }}
                            />
                          </IconButton>
                        ),
                        iconSize: [40, 40],
                        iconAnchor: [20, 40],
                      })}
                    >
                      <Popup>
                        {item.region}: {item.count} hits
                      </Popup>
                    </Marker>
                  ) : (
                    <Grid key={index}> Invalid IP and location</Grid>
                  )
                )}
            </MapContainer>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
