import React, { useRef, useState, useEffect } from "react";
import EmailEditor from "react-email-editor";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import Loader from "../Modals/Loader";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../../config";

const TemplateCreation = ({ editingTemplate, onTemplateUpdated }) => {
  const emailEditorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isEditorReady, setIsEditorReady] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (editingTemplate) {
      setTemplateName(editingTemplate.templateName);
      console.log("Editing template:", editingTemplate);
    }
  }, [editingTemplate]);

  const loadDesign = () => {
    if (isEditorReady && editingTemplate && editingTemplate.templateData) {
      console.log("Attempting to load design");
      console.log("Template data:", editingTemplate.templateData);
      
      try {
        let design;
        if (!editingTemplate.templateData.design) {
          console.warn("Design data is missing, loading empty design");
          design = { body: { rows: [] } };
        } else if (typeof editingTemplate.templateData.design === 'string') {
          design = JSON.parse(editingTemplate.templateData.design);
        } else if (typeof editingTemplate.templateData.design === 'object') {
          design = editingTemplate.templateData.design;
        } else {
          throw new Error(`Invalid design data type: ${typeof editingTemplate.templateData.design}`);
        }
        
        console.log("Parsed design:", design);
        
        if (!design || typeof design !== 'object') {
          throw new Error("Parsed design is not a valid object");
        }
        
        if (emailEditorRef.current && emailEditorRef.current.editor) {
          emailEditorRef.current.editor.loadDesign(design);
          console.log("Design loaded successfully");
        } else {
          console.error("Email editor reference is not available");
        }
      } catch (error) {
        console.error("Error loading design:", error);
        enqueueSnackbar(`Error loading template design: ${error.message}. Loading empty template.`, { variant: "warning" });
        if (emailEditorRef.current && emailEditorRef.current.editor) {
          emailEditorRef.current.editor.loadDesign({ body: { rows: [] } });
        }
      }
    } else {
      console.log("Not ready to load design yet", { isEditorReady, editingTemplate });
    }
  };

  useEffect(() => {
    if (isEditorReady && editingTemplate) {
      loadDesign();
    }
  }, [isEditorReady, editingTemplate]);

  const onReady = () => {
    console.log("Email editor is ready");
    setIsEditorReady(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTemplateName("");
  };

  const handleSaveTemplate = () => {
    if (emailEditorRef.current && isEditorReady) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { html, design } = data;
        const templateData = { html, design: JSON.stringify(design) };
        
        if (editingTemplate) {
          updateTemplate(templateData);
        } else {
          createTemplate(templateData);
        }
      });
    } else {
      console.error("Email editor is not ready yet.");
      enqueueSnackbar("Email editor is not ready. Please try again.", { variant: "error" });
    }
  };

  const createTemplate = (templateData) => {
    axios
      .post(`${BASE_URL}/email/templates/save_template`, {
        templateName,
        templateData,
      })
      .then((response) => {
        enqueueSnackbar(`Template Saved Successfully`, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
        setTemplateName("");
        handleClose();
        if (onTemplateUpdated) onTemplateUpdated();
      })
      .catch((error) => {
        const data = error.response?.data?.message || error.response?.data?.error || "Failed To Save Template";
        enqueueSnackbar(data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  const updateTemplate = (templateData) => {
    axios
      .put(`${BASE_URL}/email/templates/put/data/${editingTemplate._id}`, {
        templateName,
        templateData,
      })
      .then((response) => {
        enqueueSnackbar(`Template Updated Successfully`, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
        setTemplateName("");
        handleClose();
        if (onTemplateUpdated) onTemplateUpdated();
      })
      .catch((error) => {
        const data = error.response?.data?.message || error.response?.data?.error || "Failed To Update Template";
        enqueueSnackbar(data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  return (
    <Grid sx={{ maxHeight: "100vh", width: "100%" }}>
      <Loader timing={2000} />
      <Grid style={{ padding: "10px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            marginRight={"20px"}
            sx={{ color: "black", fontWeight: "bold", fontSize: "22px" }}
          >
            Hint:{" "}
            <span style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
              {"{{{Your Unique Name}}}"} use for merge fields
            </span>
          </Typography>

          <Button
            variant="contained"
            className="greeny"
            color="primary"
            size="small"
            onClick={handleClickOpen}
          >
            {editingTemplate ? "Update Template" : "Save Template"}
          </Button>
        </Box>
      </Grid>

      <EmailEditor 
        ref={emailEditorRef} 
        onReady={onReady}
        onLoad={() => console.log("Editor loaded")}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingTemplate ? "Update Template Name" : "Provide Template Name"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Template Name"
            fullWidth
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "white" }} className="redy">
            Cancel
          </Button>
          <Button
            onClick={handleSaveTemplate}
            sx={{ color: "white" }}
            disabled={!templateName || !isEditorReady}
            className="greeny"
          >
            {editingTemplate ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TemplateCreation;