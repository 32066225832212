export const apiHdrDefValue = {
    APPLICATION_JSON: "application/json",
    REFERRED_BY: "Web",
    FORM_URLENCODED: "application/x-www-form-urlencoded",
    FORM_DATA: "multipart/form-data",
};

export const apiHttpStatus = {
    SC_200: 200,
    SC_201: 201,
    SC_202: 202,
    SC_400: 400,
    SC_403: 403,
    SC_412: 412,
    SC_424: 424,
    SC_401: 401,
};

export const apiEndPoint = {

    // ADMIN RELATED ROUTES 
    USER_ROLE_FETCHER: "/user/",
    USER_REGISTER: "/user",
    GET_ALL_USERS: "/users/",
    ADMIN_LOGIN: "/login",
    USER_BRANDING_DATA:"/branding/",
    CUSTOMER_DATA:"/customer/",
    CUSTOMERS_DATA:"/customers/",
    DASHBOARD:"/dashboard",
    CREATE_LINK:"/create_shorturl",
    GET_LINK:"/data/links",
    GET_STATS:"/data/stats/",
    VERIFY_PROTECT_EMAIL:"/verify_email/",
    PAYMENT:"/user_payment",
    PLAN_CREATION:"/plan/data/create_plan",
    PLAN_FETCHER:"/plan/data/getall_plan",
    PLAN_UPDATE:"/plan/data/update_plan",
    PLAN_DELETER:"/plan/data/delete_plan",
    All_PLAN_FETCHER:"/plan/data/getall_plandata",
    DASHBOARD_DATA:"/user/data/dashboard/",
    DASHBOARD_CHART_DATA:"/user/data/dashboard/chart_data/",
   CUSTOM_DOMAIN_USER_ENTRY:"/users/apiv1/custom_domain/verfication_stage",
   CUSTOM_DOMAIN_DEACTIVATE:"/users/apiv1/custom_domain/deletion",
   CUSTOM_DOMAIN_ACTIVATE:"/users/apiv1/custom_domain/creation",
   CUSTOM_DOMAIN_DELETE:"/users/apiv1/custom_domain/delete_entry",
   SUPPORT_GET:"/api/user/support_user/get",
   SUPPORT_CREATE:"/api/user/support_user/create",
   SUPPORT_UPDATE:"/api/user/support_user/update",
   SUPPORT_DELETE:"/api/user/support_user/delete",
   SUPPORT_GET_ROLE:"/api/user/support_user/get_role",
   ALL_TICKET_GET:"/ticket/data/getall_ticket",
};

