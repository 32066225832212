import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  TextField,
  Tooltip,
  Grid,
  Badge,
  Modal,
  CircularProgress,
  Paper,
  Chip,
} from "@mui/material";
import {
  Close as CloseIcon,
  Notifications as NotificationsIcon,
  ArrowDownward as ArrowDownwardIcon,
  Send as SendIcon,
  SupportAgent as SupportAgentIcon,
  AttachFile as AttachFileIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import io from "socket.io-client";
import logo from "../../Assets/Images/applnx.png";
import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import EndChatModal from "./EndChatModal";
import axios from "axios";
import { BASE_URL } from "../../config";
import UploadButton from "../Buttons/ChatAttachmentUploadButton/Uploadbutton";
import DownloadButton from "../Buttons/ChatAttachmentDownloadButton/DownloadButton";
// import VoiceRecorder from "../Buttons/VoiceRecordChat/VoiceRecorder";
import { SOCKET_URL } from "../../config";

const socket = io(SOCKET_URL, {
  path: "/api/user/v1/live_chat",
});

function EnhancedChatBox({ onClose, data, openTicket }) {
  
  const [ticket, setTicket] = useState(null);
  const [ticketInitial, setTicketIntial] = useState(false);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([
    {
      message: `Create A Ticket To Get Started`,
      sender: "System",
      timestamp: new Date(),
    },
  ]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [queryInput, setQueryInput] = useState("");
  const [isQueryInputMode, setIsQueryInputMode] = useState(false);
  const chatContainerRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [countdown, setCountdown] = useState(180);
  const [hasSentFinalMessage, setHasSentFinalMessage] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [attachments, setAttachments] = useState({});
  const [scale, setScale] = useState(1);


  const handleWheel = (event) => {
    event.preventDefault();
    if (event.deltaY < 0) {
      setScale((prev) => Math.min(prev + 0.1, 3));
    } else {
      setScale((prev) => Math.max(prev - 0.1, 0.5));
    }
  };


  // const handleSendVoiceMessage = async (audioBlob) => {
  //   if (audioBlob && ticket) {
  //     const formData = new FormData();
  //     formData.append("file", audioBlob, "voice_message.wav");
  //     formData.append("ticket_id", ticket.ticket_id);
  //     formData.append("sender", "Sales Employee");

  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/api/user/v1/live_chat/voice_upload`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );
  //       enqueueSnackbar("Voice message sent successfully", {
  //         variant: "success",
  //       });
  //       // Emit a socket event to notify about the new voice message
  //       socket.emit("newVoiceMessage", {
  //         ticket_id: ticket.ticket_id,
  //         file: response.data.file,
  //       });
  //     } catch (error) {
  //       console.error("Error sending voice message:", error);
  //       enqueueSnackbar("Failed to send voice message", {
  //         variant: "error",
  //       });
  //     }
  //   }
  // };


  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    multiple: true,
  });

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0 && ticket) {
      setIsUploading(true);
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("ticket_id", ticket.ticket_id);
        formData.append("sender", "Customer");
        try {
          const response = await axios.post(
            `${BASE_URL}/live_chat/file_upload/chat_attachment`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          enqueueSnackbar(`${file.name} uploaded successfully`, {
            variant: "success",
          });
        } catch (error) {
          console.error("Error uploading file:", error);
          enqueueSnackbar(`Failed to upload ${file.name}`, {
            variant: "error",
          });
        }
      }
      setIsUploading(false);
      setIsCompleted(true);
      setTimeout(() => {
        setIsCompleted(false);
        setShowFileUploadModal(false);
      }, 4000);

      setSelectedFiles([]);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  useEffect(() => {
    socket.on("attachmentUploaded", ({ ticket_id, attachment }) => {
      if (ticket && ticket.ticket_id === ticket_id) {
        setIsUploading(false);
        enqueueSnackbar("File uploaded successfully", { variant: "success" });
        setChat((prevChat) =>
          prevChat.map((msg) =>
            msg.attachment && msg.attachment.isLoading
              ? {
                  ...msg,
                  attachment: {
                    ...attachment,
                    isLoading: false,
                  },
                }
              : msg
          )
        );
      }
    });

    socket.on("attachmentError", (errorMessage) => {
      setIsUploading(false);
      enqueueSnackbar(errorMessage, { variant: "error" });
    });

    return () => {
      socket.off("attachmentUploaded");
      socket.off("attachmentError");
    };
  }, [ticket, enqueueSnackbar]);

  useEffect(() => {
    socket.on("attachmentData", ({ path, data }) => {
      setAttachments((prev) => ({ ...prev, [path]: data }));
    });

    return () => {
      socket.off("attachmentData");
    };
  }, []);

  const loadImageAttachments = useCallback(
    (chatMessages) => {
      chatMessages.forEach((message) => {
        if (
          message.attachment &&
          message.attachment.contentType.startsWith("image/") &&
          !attachments[message.attachment.path]
        ) {
          socket.emit("getAttachment", { path: message.attachment.path });
        }
      });
    },
    [attachments]
  );

  const renderAttachment = (attachment) => {
    if (!attachment) return null;

    if (attachment.contentType.startsWith("image/")) {
      const imageData = attachments[attachment.path];
      return imageData ? (
        <img
          src={`data:${attachment.contentType};base64,${imageData}`}
          alt={attachment.filename}
          style={{ maxWidth: "100%", maxHeight: "200px", cursor: "pointer" }}
          onClick={() =>
            setFullScreenImage({
              src: `data:${attachment.contentType};base64,${imageData}`,
              filename: attachment.filename,
            })
          }
        />
      ) : (
        <CircularProgress size={24} />
      );
    } else {
      return (
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"12px"}}>
          <Grid>
            <Typography variant="body2" fontWeight={"600"}>{attachment.filename}</Typography>
          </Grid>
          <Grid mt={0.2}>
            <DownloadButton attachment={attachment} socket={socket} />
          </Grid>
        </Box>
      );
    }
  };

  useEffect(() => {
    let countdownInterval;

    if (isWaiting) {
      countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            clearInterval(countdownInterval);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isWaiting]);

  useEffect(() => {
    if (isWaiting) {
      const minutes = Math.floor(countdown / 60);
      const seconds = countdown % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      setChat((prevChat) => [
        ...prevChat.filter((msg) => msg.sender !== "Countdown"),
        {
          message: `Kindly wait while you are connected to support. Estimated Time ${formattedTime} remaining.`,
          sender: "Countdown",
          timestamp: new Date(),
        },
      ]);
    }
    if (countdown === 0 && !hasSentFinalMessage) {
      setChat((prevChat) => [
        ...prevChat.filter((msg) => msg.sender !== "Countdown"),
        {
          message: "Support will connect with you shortly.",
          sender: "System",
          timestamp: new Date(),
        },
      ]);
      setIsWaiting(false);
      setHasSentFinalMessage(true);
    }
  }, [countdown, isWaiting, hasSentFinalMessage]);

  const endChatHandler = (ticket_id, feedback) => {
    socket.emit("endChatWithFeedback", { ticket_id, feedback });
    onClose();
  };

  useEffect(() => {
    socket.on("ticketCreated", (newTicket) => {
      setTicket(newTicket);
      setIsWaiting(true);
      setCountdown(180);
      setIsQueryInputMode(false);
    });

    socket.on("ticketStatusUpdated", ({ ticket_id, status, salesName }) => {
      if (
        ticket &&
        ticket.ticket_id === ticket_id &&
        status === "in-progress"
      ) {
        setIsWaiting(false);
        setChat((prevChat) => [
          ...prevChat,
          {
            message: `You have been connected with sales executive ${salesName}. Kindly ask your queries.`,
            sender: "System",
            timestamp: new Date(),
          },
        ]);
        socket.emit("sendMessage", {
          data: ticket,
          message: `Hi ${data.user_name}, I am ${salesName}. How may I assist you?`,
          sender: "Sales",
        });
      }
    });

    socket.on(
      "newMessage",
      ({ ticket_id, message, sender, timestamp, attachment }) => {
        if (ticket && ticket.ticket_id === ticket_id) {
          setChat((prevChat) => [
            ...prevChat,
            { message, sender, timestamp, attachment },
          ]);
          loadImageAttachments([{ message, sender, timestamp, attachment }]);
          if (isMinimized) {
            enqueueSnackbar(`You Have A New Notification From Support`, {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            setUnreadCount((prevCount) => Math.min(prevCount + 1));
          }
        }
      }
    );

    socket.on("error", (errorMessage) => {
      enqueueSnackbar(`${errorMessage}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    });

    return () => {
      socket.off("ticketCreated");
      socket.off("ticketStatusUpdated");
      socket.off("newMessage");
      socket.off("userTickets");
      socket.off("error");
    };
  }, [ticket, data.user_name, isMinimized]);

  useEffect(() => {
    if (data.user_uid) {
      socket.emit("fetchUserTickets", data.user_uid);
      socket.on("userTickets", (openTickets) => {
        const latestOpenTicket =
          openTickets.length > 0 ? openTickets[0] : openTicket;
        if (
          latestOpenTicket &&
          latestOpenTicket.chat &&
          latestOpenTicket.chat.length > 0
        ) {
          setTicket(latestOpenTicket);
          const mappedChat = latestOpenTicket.chat.map((c) => ({
            message: c.message,
            sender: c.sender,
            timestamp: c.timestamp ? new Date(c.timestamp) : new Date(),
            attachment: c.attachment,
          }));
          setChat(mappedChat);
          loadImageAttachments(mappedChat);
          const systemMessage = {
            message:
              "Resuming your previous chat Because You Have An Open Ticket...",
            sender: "System",
            timestamp: new Date(),
          };
          setChat((prevChat) => [...prevChat, systemMessage]);
          setTimeout(() => {
            setChat((prevChat) =>
              prevChat.filter((msg) => msg !== systemMessage)
            );
          }, 8000);
          setIsWaiting(latestOpenTicket.status === "open");
          setTicketIntial(true);
        }
      });
      return () => {
        socket.off("userTickets");
      };
    }
  }, [data.user_uid, openTicket]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const handleScroll = () => {
        if (
          chatContainer.scrollTop <
          chatContainer.scrollHeight - chatContainer.clientHeight - 100
        ) {
          setShowScrollToBottom(true);
        } else {
          setShowScrollToBottom(false);
        }
      };
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [chat]);

  const handleCreateTicket = () => {
    setTicketIntial(true);
    setIsQueryInputMode(true);
    setChat((prevChat) => [
      ...prevChat,
      {
        message: `Hi ${data.user_name}, Enter your query to proceed.`,
        sender: "System",
        timestamp: new Date(),
      },
    ]);
  };

  const handleMinimize = () => {
    setIsMinimized((prevState) => !prevState);
    if (!isMinimized) setUnreadCount(0);
  };

  useEffect(() => {
    if (!isMinimized && chatContainerRef.current) {
      const scrollTimeout = setTimeout(() => {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 500);
      return () => clearTimeout(scrollTimeout);
    }
  }, [isMinimized]);

  const handleQuerySubmit = () => {
    setChat((prevChat) => [
      ...prevChat,
      {
        message: `${queryInput}`,
        sender: "Customer",
        timestamp: new Date(),
      },
    ]);
    if (queryInput.trim()) {
      socket.emit("createTicket", {
        user_name: data.user_name,
        email_id: data.email_id,
        user_uid: data.user_uid,
        query: queryInput,
        chat: chat,
      });
      setQueryInput("");
    }
  };

  const handleSendMessage = () => {
    if (message && ticket) {
      socket.emit("sendMessage", {
        data: ticket,
        message,
        sender: "Customer",
      });
      setMessage("");
    }
  };

  const handleScrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <motion.div
      className="bluey"
      animate={{
        height: isMinimized ? "70px" : "650px",
        opacity: isMinimized ? 1 : 1,
      }}
      initial={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.8 }}
      style={{
        width: "460px",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        boxShadow: 3,
        borderRadius: isMinimized ? 20 : 15,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderBottom: "2px solid #ddd",
        }}
      >
        <Avatar src={logo} alt="Support" sx={{ marginRight: 2 }} />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            background: "White",
            justifyContent: "center",
            marginRight: "9px",
            borderRadius: "50px",
          }}
        >
          <SupportAgentIcon
            sx={{ color: "mediumvioletred", marginRight: 1, fontSize: "36px" }}
          />
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "black", fontSize: "17px" }}
          >
            Customer Support
          </Typography>
        </Box>
        {!ticketInitial && (
          <Button
            size="small"
            variant="contained"
            onClick={handleCreateTicket}
            sx={{ marginRight: 1, fontWeight: "800" }}
            className="greeny"
          >
            Raise Ticket
          </Button>
        )}
        {isMinimized && unreadCount > 0 && (
          <Tooltip title="Notification">
            <Badge
              onClick={handleMinimize}
              badgeContent={unreadCount > 4 ? "4+" : unreadCount}
              color="error"
              invisible={!isMinimized || unreadCount === 0}
              sx={{ marginRight: 1.5, color: "white", cursor: "pointer" }}
            >
              <NotificationsIcon sx={{ color: "white" }} />
            </Badge>
          </Tooltip>
        )}
        {ticketInitial && !isQueryInputMode && (
          <IconButton
            size="small"
            onClick={handleMinimize}
            className="greeny"
            sx={{
              marginRight: 1,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              width: "36px",
              height: "36px",
            }}
          >
            {isMinimized ? (
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  border: "2px solid white",
                  borderRadius: "2px",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "16px",
                  height: "2px",
                  backgroundColor: "white",
                }}
              />
            )}
          </IconButton>
        )}

        <IconButton
          onClick={handleClose}
          size="small"
          className="redy"
          sx={{ color: "white" }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
      {!isMinimized && (
        <>
          <Box
            ref={chatContainerRef}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: 2,
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(0,0,0,0.1)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "4px",
              },
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "calc(100% - 100px)",
            }}
          >
            {chat.map((c, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection:
                    c.sender === "Customer" ? "row-reverse" : "row",
                  marginBottom: 1.5,
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "70%",
                    width: "fit-content",
                    backgroundColor:
                      c.sender === "Customer" ? "#d0f0c0" : "#f0f0f0",
                    padding: 1,
                    borderRadius: 2,
                    wordWrap: "break-word",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "black",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {c.message}
                  </Typography>
                  {c.attachment && renderAttachment(c.attachment)}
                  <Typography
                    variant="caption"
                    sx={{
                      alignSelf: "flex-end",
                      color: "rgba(0, 0, 0, 0.6)",
                      marginTop: "4px",
                      fontWeight: "800",
                      fontSize: "10px",
                    }}
                  >
                    {new Date(c.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Typography>
                </Box>
              </Box>
            ))}

            {isWaiting && (
              <Typography
                variant="body2"
                sx={{ fontStyle: "italic", color: "white" }}
              >
                Connecting...
              </Typography>
            )}
          </Box>

          {showScrollToBottom && (
            <IconButton
              className="blacky"
              onClick={handleScrollToBottom}
              sx={{
                position: "absolute",
                bottom: "130px",
                right: "16px",
                backgroundColor: "#007bff",
                color: "white",
                zIndex: 1001,
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
          )}

          <Box
            sx={{
              padding: 2,
              borderTop: 2,
              borderColor: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isQueryInputMode ? (
              <>
                <TextField
                  fullWidth
                  placeholder="Enter your query..."
                  value={queryInput}
                  onChange={(e) => setQueryInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleQuerySubmit();
                    }
                  }}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                    background: "white",
                    borderRadius: "6px",
                  }}
                />
                <Tooltip title="Send">
                  <IconButton
                    className="orangy"
                    onClick={handleQuerySubmit}
                    disabled={!queryInput.trim()}
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SendIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  placeholder="Type your message here..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !isWaiting && message) {
                      handleSendMessage();
                    }
                  }}
                  disabled={isWaiting || !ticket}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                    background: "white",
                    borderRadius: "6px",
                  }}
                />
                <Tooltip title="Attach File">
                  <IconButton
                    onClick={() => setShowFileUploadModal(true)}
                    disabled={isWaiting || !ticket}
                    className="orangy"
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  >
                    <AttachFileIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
                {/* <VoiceRecorder onSendVoiceMessage={handleSendVoiceMessage} /> */}
                <Tooltip title="Send">
                  <IconButton
                    variant="contained"
                    onClick={handleSendMessage}
                    disabled={isWaiting || !ticket || !message}
                    className="orangy"
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SendIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
          <Grid
            container
            sx={{
              padding: 1,
              borderTop: "2px solid #ddd",
              backgroundColor: "#333",
              color: "white",
              width: "100%",
              textAlign: "center",
              borderRadius: "0 0 15px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={ticket ? 9 : 12}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
              >
                Designed and developed by QRS &copy; {new Date().getFullYear()}{" "}
                All rights reserved.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {ticket && (
                <EndChatModal ticket={ticket} endChatHandler={endChatHandler} />
              )}
            </Grid>
          </Grid>
        </>
      )}

      <Modal
        open={showFileUploadModal}
        onClose={() => {
          setShowFileUploadModal(false);
          setSelectedFiles([]);
        }}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            maxHeight: "70vh",
            overflow: "hidden",
          }}
        >
          {/* Upper Section */}
          <Box
            sx={{
              flexShrink: 0,
            }}
          >
            <Typography
              id="file-upload-modal"
              variant="h6"
              component="h2"
              mb={2}
              textAlign={"center"}
              fontWeight={"800"}
            >
              Upload Files
            </Typography>
            <Paper
              {...getRootProps()}
              sx={{
                p: 2,
                textAlign: "center",
                cursor: "pointer",
                border: "2px solid mediumvioletred",
                bgcolor: isDragActive ? "action.hover" : "background.paper",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                Drag & drop files here, or click to select files
              </Typography>
              <CloudUploadIcon
                sx={{ fontSize: 48, my: 2, color: "mediumvioletred" }}
              />
            </Paper>
          </Box>

          {/* Scrollable Chips Section */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              mt: 2,
              mb: 2,
            }}
          >
            {selectedFiles.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleRemoveFile(file)}
                sx={{ mb: 1 }}
              />
            ))}
          </Box>

          {/* Fixed Button Section */}
          <Box
            sx={{
              flexShrink: 0,
              mt: 2,
            }}
          >
            {" "}
            <UploadButton
              isUploading={isUploading}
              isCompleted={isCompleted}
              handleClick={handleFileUpload}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
      open={!!fullScreenImage}
      onClose={() => setFullScreenImage(null)}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <img
        src={fullScreenImage?.src}
        alt={fullScreenImage?.filename}
        onWheel={handleWheel}
        style={{
          maxWidth: "90%",
          maxHeight: "90%",
          transform: `scale(${scale})`,
          transition: "transform 0.2s ease",
        }}
      />
    </Modal>
    </motion.div>
  );
}

export default EnhancedChatBox;
