let BASE_URL;
let URL;
let HOST;
let SOCKET_URL;


const server = 'TEST';

//Frontend URL String
if (server === 'LOCAL') {
  URL = 'http://localhost:3000';
} 
else if (server === 'TEST') {
  URL = 'https://dev-app.datainterface.io';
} 
else if (server === 'PROD') {
  URL = 'https://app.applnx.io';
} 
else {
  URL = 'https://dev-app.datainterface.io';
}


//Backend COnnectivity
if (server === 'LOCAL') {
  BASE_URL = 'http://localhost:8084';
} 
else if (server === 'TEST') {
  BASE_URL = 'https://dev-app-backend.datainterface.io';
} 
else if (server === 'PROD') {
  BASE_URL = 'https://applnx.io';
} 
else {
  BASE_URL = 'https://dev-app-backend.datainterface.io';
}

//socket COnnectivity
if (server === 'LOCAL') {
  SOCKET_URL = 'http://localhost:8084';
} 
else if (server === 'TEST') {
  SOCKET_URL = 'https://dev-app-backend.datainterface.io';
} 
else if (server === 'PROD') {
  SOCKET_URL = 'https://applnx.io';
} 
else {
  SOCKET_URL = 'https://dev-app-backend.datainterface.io';
}

//HOST URL String For Swagger
if (server === 'LOCAL') {
  HOST = 'localhost:8084';
} 
else if (server === 'TEST') {
  HOST = 'dev-app.datainterface.io';
} 
else if (server === 'PROD') {
  HOST = 'applnx.io';
} 
else {
  HOST = 'dev-app.datainterface.io';
}

export { BASE_URL, URL ,HOST ,SOCKET_URL};
