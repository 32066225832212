import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { apiEndPoint } from "../../../Service/ApiConstant";
import Controller from "../../../Service/ApiController";

import Loader from "../../Modals/Loader";

const ITEMS_PER_PAGE = 5;

const ManageUsersTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    email: "",
    name: "",
    role: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentPageUsers, setCurrentPageUsers] = useState(1);
  const [roles] = useState(["Support_User", "Support_Admin"]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const fetchUsers = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.SUPPORT_GET,
        "GET"
      );
      if (result.success === true) {
        setUsers(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin Recharge", error);
    }
  };

  const saveUser = async () => {
    try {
      const URL = isEditing
        ? `${apiEndPoint.SUPPORT_UPDATE}`
        : `${apiEndPoint.SUPPORT_CREATE}`;
      const METHOD = isEditing ? "PUT" : "POST";
      const data = currentUser;
      const result = await Controller.ApiController(data, URL, METHOD);
      if (result.success === true) {
        enqueueSnackbar(`${result.data.message}`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchUsers();
        handleUserModalClose();
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error saving user`, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };

  const confirmDeleteUser = (user) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  // Handle the actual delete after confirmation
  const handleDeleteUser = async () => {
    try {
      const data = userToDelete;
      const result = await Controller.ApiController(
        data,
        apiEndPoint.SUPPORT_DELETE,
        "POST"
      );
      if (result.success === true) {
        enqueueSnackbar(`${result.data.message}`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchUsers();
        setDeleteModalOpen(false);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error Deleteing user`, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };

  const handleUserModalOpen = (user = { email: "", name: "", role: "" }) => {
    setCurrentUser(user);
    setIsEditing(!!user.email);
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
    setCurrentUser({ email: "", name: "", role: "" });
  };

  const handleUserChange = (e) => {
    setCurrentUser({
      ...currentUser,
      [e.target.name]: e.target.value,
    });
  };

  const handlePageChangeUsers = (event, value) => setCurrentPageUsers(value);

  useEffect(() => {
    fetchUsers();
  }, []);

  const paginatedUsers = users.slice(
    (currentPageUsers - 1) * ITEMS_PER_PAGE,
    currentPageUsers * ITEMS_PER_PAGE
  );

  return (
    <Box sx={{ padding: 2 }}>
        <Loader timing={2000} /> 
      <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          className="greeny"
          onClick={() => handleUserModalOpen()}
        >
          Add User
        </Button>
      </Grid>
      {paginatedUsers.length === 0 ? (
           <Typography textAlign={"center"} fontWeight={800} mt={5}>  No users available. Try to add users and try again.</Typography>
      ) : (
        <>
         <TableContainer component={Paper} sx={{marginTop:"10px"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Sno
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Email
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Role
                </TableCell>
                <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user, index) => (
                <TableRow key={user.email}  sx={{
                  backgroundColor:
                    index % 2 === 0 ? "white" : "grey.200",
                }}>
                  <TableCell    sx={{ fontWeight: 800, textAlign: "center" }}>
                    {(currentPageUsers - 1) * ITEMS_PER_PAGE + index + 1}
                  </TableCell>
                  <TableCell  sx={{ textAlign: "center" }}>{user.name}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{user.email}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{user.role}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      sx={{ marginRight: "10px" }}
                      onClick={() => handleUserModalOpen(user)}
                      className="orangy"
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      onClick={() => confirmDeleteUser(user)}
                      className="pinky"
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Pagination
              count={Math.ceil(users.length / ITEMS_PER_PAGE)}
              page={currentPageUsers}
              onChange={handlePageChangeUsers}
            />
          </Box>
        </>
      )}

      {/* User Form Modal */}
      <Modal open={userModalOpen} onClose={handleUserModalClose}>
        <Box
          sx={{
            width: 400,
            padding: 3,
            borderRadius: 2,
            backgroundColor: "white",
            boxShadow: 3,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6" mb={2} textAlign={"center"}>
            {isEditing ? "Edit User" : "Add User"}
          </Typography>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={currentUser.name}
            onChange={handleUserChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={currentUser.email}
            onChange={handleUserChange}
            sx={{ marginBottom: 2 }}
            disabled={isEditing}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={currentUser.role}
              onChange={handleUserChange}
              label="Role"
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={handleUserModalClose} sx={{ marginRight: 1 }} variant="contained"  className="redy">
              Cancel
            </Button>
            <Button onClick={saveUser} variant="contained" className="greeny" sx={{color:"white"}}>
              {isEditing ? "Save" : "Add"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box
          sx={{
            width: 400,
            padding: 3,
            borderRadius: 2,
            backgroundColor: "white",
            boxShadow: 3,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6" mb={2}>
            Confirm Deletion
          </Typography>
          <Typography mb={2}>
            Are you sure you want to delete {userToDelete?.name}?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setDeleteModalOpen(false)}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteUser}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageUsersTab;
