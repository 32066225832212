import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Pagination,
  TableContainer,
  Paper,
  Button,
  Modal,
  Fade,
  Backdrop,
  IconButton,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Loader from "../../Modals/Loader";
import { apiEndPoint } from "../../../Service/ApiConstant";
import Controller from "../../../Service/ApiController";

const ITEMS_PER_PAGE = 5;

const getStarColor = (rating) => {
  switch (rating) {
    case 1:
      return "#ff4444"; // Red
    case 2:
      return "#ff8800"; // Orange
    case 3:
      return "#1689ff"; // Blue
    case 4:
      return "#99cc00"; // Light green
    case 5:
      return "#00c851"; // Green
    default:
      return "grey"; // Default color if rating is invalid
  }
};

const AllTicketsTab = () => {
  const [tickets, setTickets] = useState([]);
  const [currentPageAllTickets, setCurrentPageAllTickets] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState("");

  const fetchTickets = async (page) => {
    setLoading(true);
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.ALL_TICKET_GET+`?page=${page}&limit=${ITEMS_PER_PAGE}`,
        "GET"
      );
      setTickets(result.data.tickets);
      setTotalPages(result.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch tickets", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets(currentPageAllTickets);
  }, [currentPageAllTickets]);

  const handlePageChangeAllTickets = (event, value) => {
    setCurrentPageAllTickets(value);
  };

  const handleOpenModal = (feedback) => {
    setSelectedFeedback(feedback);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Loader timing={2000} />
      {loading ? (
        <Typography textAlign={"center"} fontWeight={800} mt={5}>
          Loading tickets...
        </Typography>
      ) : tickets.length === 0 ? (
        <Typography textAlign={"center"} fontWeight={800} mt={5}>
          No tickets available.
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: "15px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    SNO
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Ticket ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Customer Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Query
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Attended By
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Rating
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Feedback
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.map((ticket, index) => (
                  <TableRow
                    key={ticket.ticket_id}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? "white" : "grey.200",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "22px",
                      }}
                    >
                      {(currentPageAllTickets - 1) * ITEMS_PER_PAGE + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {ticket.ticket_id}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {ticket.user_name || "Unknown"}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {ticket.query}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {ticket.assigned_user}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {ticket.status}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      {[...Array(5)].map((_, starIndex) => (
                        <IconButton key={starIndex} disabled>
                          <StarIcon
                            sx={{
                              color:
                                starIndex + 1 <= ticket.rating
                                  ? getStarColor(ticket.rating)
                                  : "grey",
                            }}
                          />
                        </IconButton>
                      ))}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "22px" }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleOpenModal(ticket.feedback)}
                        disabled={!ticket.feedback}
                        className="greeny"
                      >
                        View Feedback
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Pagination
              count={totalPages}
              page={currentPageAllTickets}
              onChange={handlePageChangeAllTickets}
            />
          </Box>
          <Modal
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" component="h2">
                  Feedback
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  {selectedFeedback || "No feedback available."}
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default AllTicketsTab;
