import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import AssignTicketsTab from "./AssignTicketsTab";
import ManageUsersTab from "./ManageUsersTab";
import AllTicketsTab from "./AllTicketsTab";
import AttendTickets from "./AttendTickets";

const Support = ({ role, supportUserRole ,support_email_id}) => {
  const [tabValue, setTabValue] = useState(0);

  // Define tab items based on roles
  const tabsConfig = {
    admin: [
      { label: "Assign Tickets", component: <AssignTicketsTab role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> },
      { label: "Attend Tickets", component: <AttendTickets role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> },
    ],
    superadmin: [
      { label: "Assign Tickets", component: <AssignTicketsTab role={role} supportUserRole={supportUserRole} support_email_id={support_email_id}  /> },
      { label: "Manage Users", component: <ManageUsersTab role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> },
      { label: "Attend Tickets", component: <AttendTickets role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> },
      { label: "All Tickets", component: <AllTicketsTab role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> }
    ],
    support: [
      { label: "Attend Tickets", component: <AttendTickets role={role} supportUserRole={supportUserRole} support_email_id={support_email_id} /> }
    ]
  };

  const isSuperAdmin = role.includes('Support_Super_Admin');
  const isAdmin = supportUserRole === 'Support_Admin';
  const isSupport = supportUserRole === 'Support_User';

  const availableTabs = isSuperAdmin ? tabsConfig.superadmin :isAdmin ? tabsConfig.admin :isSupport ? tabsConfig.support : [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
        centered
        TabIndicatorProps={{ style: { display: "none" } }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiTab-root": {
            borderRadius: "40px",
            padding: "12px 24px",
            minWidth: "150px",
            margin: "0 10px",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "800",
            backgroundColor: "grey",
            color: "white",
            transition: "background-color 0.3s ease",
          },
          "& .Mui-selected": {
            background:
              "linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0))",
            color: "white !important",
          },
        }}
      >
        {availableTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      {availableTabs[tabValue] && availableTabs[tabValue].component}
    </div>
  );
};

export default Support;

