import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  Divider,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { apiEndPoint } from "../../Service/ApiConstant";
import Controller from "../../Service/ApiController";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import lottie from "lottie-web";

const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    const propName = parent ? parent + "." + key : key;
    if (
      typeof obj[key] === "object" &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const MasterRechargeHistory = ({ uid, role }) => {
  const [values, setValues] = useState({});
  const animationContainer = useRef(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const handleViewMore = (payment) => {
    setSelectedPayment(payment);
  };

  const handleCloseModal = () => {
    setSelectedPayment(null);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + uid,
        "GET"
      );
      if (result.success === true) {
        const sortedData = {
          ...result.data,
          recharge_history: {
            ...result.data.recharge_history,
            successful_payments:
              result.data.recharge_history.successful_payments.sort(
                (a, b) => new Date(b.date) - new Date(a.date)
              ),
          },
        };
        setValues(sortedData);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/539cb163-8de7-4480-afc7-32f577b62b1e/Hjmv4Rx511.json",
    });
  }, []);

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ height: "calc(100vh - 120px)" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {values &&
            values.recharge_history &&
            values.recharge_history.successful_payments &&
            values.recharge_history.successful_payments.length > 0 ? (
              <>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 800,
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      TRANSACTION HISTORY
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  style={{ backgroundColor: "grey", margin: "10px 0" }}
                />
              </>
            ) : (
              <></>
            )}

            <Paper
              style={{
                overflowX: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {values &&
              values.recharge_history &&
              values.recharge_history.successful_payments &&
              values.recharge_history.successful_payments.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Transaction ID
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Amount
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Payment Type
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Purchased Plan
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 800, textAlign: "center" }}
                          >
                            Status
                          </TableCell>
                          {role === "superadmin" && (
                            <TableCell   sx={{ fontWeight: 800, textAlign: "center" }}>More Details</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values &&
                          values.recharge_history &&
                          values.recharge_history.successful_payments &&
                          values.recharge_history.successful_payments
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((payment, index) => (
                              <TableRow
                                key={payment.transaction_id}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "white" : "grey.200",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {payment.transaction_id}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {payment.amount}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {new Date(payment.date).toLocaleString()}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {payment.payment_type}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {payment.plan_name}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {payment.status}
                                </TableCell>
                                {role === "superadmin" && (
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <Button
                                      variant="contained"
                                      className="greeny"
                                      onClick={() => handleViewMore(payment)}
                                    >
                                      View More
                                    </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      Rows per page:
                      <Select
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      >
                        {[5, 10, 20].map((rowsPerPageOption) => (
                          <MenuItem
                            key={rowsPerPageOption}
                            value={rowsPerPageOption}
                          >
                            {rowsPerPageOption}
                          </MenuItem>
                        ))}
                      </Select>
                    </Typography>
                    <Typography variant="body2">
                      {page * rowsPerPage + 1}-
                      {Math.min(
                        (page + 1) * rowsPerPage,
                        values &&
                          values.recharge_history &&
                          values.recharge_history.successful_payments &&
                          values.recharge_history.successful_payments.length
                      )}{" "}
                      of{" "}
                      {values &&
                        values.recharge_history &&
                        values.recharge_history.successful_payments &&
                        values.recharge_history.successful_payments.length}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={() => handleChangePage(page - 1)}
                        disabled={page === 0}
                      >
                        <ChevronLeft />
                      </IconButton>
                      <IconButton
                        onClick={() => handleChangePage(page + 1)}
                        disabled={
                          page >=
                          Math.ceil(
                            values &&
                              values.recharge_history &&
                              values.recharge_history.successful_payments &&
                              values.recharge_history.successful_payments
                                .length / rowsPerPage
                          ) -
                            1
                        }
                      >
                        <ChevronRight />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        ref={animationContainer}
                        style={{ width: "100%", height: "60%" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ padding: "20px", color: "grey.600" }}
                      >
                        You have no purchases available, kindly make some
                        purchases.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {selectedPayment && (
        <Modal
          open={Boolean(selectedPayment)}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              maxHeight: "80vh",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-title" variant="h6" component="h2">
              Payment Details
            </Typography>
            <Box mt={2}>
              {Object.entries(flattenObject(selectedPayment)).map(
                ([key, value]) => (
                  <Typography key={key}>
                    <strong>{key}:</strong> {JSON.stringify(value)}
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default MasterRechargeHistory;
