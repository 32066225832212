import React, { useState } from "react";
import axios from "axios";
import CheckoutForm from "./CheckOutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BASE_URL } from "../../../../config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Paper, Typography, Box } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = ({
  amount,
  currencySymbol,
  id,  
  onSuccess,
  onCancel,
  region,
  currency,

}) => {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [email, setEmail] = useState("");
  const [showCheckout, setShowCheckout] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/stripe/intialize_payment`,
          {
            amount: amount * 100,
            currency: currency,
            country: region,
            email: values.email,
          }
        );

        setClientSecret(response.data.clientSecret);
        setPaymentIntentId(response.data.paymentIntentId);
        setEmail(values.email);
        setShowCheckout(true);
      } catch (error) {
        console.error("Error initializing payment:", error);
      }
    },
  });

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: "2rem", width: "100%", margin: "0 auto" }}
    >
      <>
        <Typography variant="h4" component="h4" align="center" gutterBottom>
          {showCheckout ? "Checkout" : "Enter Your Email"}
        </Typography>
        {!showCheckout ? (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              {...formik.getFieldProps("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              type="submit"
              variant="contained"
              size="small"
              fullWidth
              sx={{ marginTop: "2rem" }}
              disabled={formik.isSubmitting}
            >
              Proceed to Checkout
            </Button>
          </form>
        ) : (
          clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                amount={amount}
                currencySymbol={currencySymbol}
                id={id}
                onSuccess={onSuccess}
                onCancel={onCancel}
                paymentIntentId={paymentIntentId}
                email={email}
              />
            </Elements>
          )
        )}
      </>
    </Paper>
  );
};

export default Stripe;
