import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Components/PrivateRoute/AuthContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
const theme = createTheme();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ThemeProvider theme={theme}>
    <SnackbarProvider>
        <AuthProvider>
           <App />
        </AuthProvider>
    </SnackbarProvider>
  </ThemeProvider>

);
reportWebVitals();
