import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton as MUIIconButton,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import lottie from "lottie-web";
import { useSnackbar } from "notistack";
import { motion, AnimatePresence } from "framer-motion";
import Controller from "../../Service/ApiController";
import { apiEndPoint } from "../../Service/ApiConstant";
import DeleteIcon from "@mui/icons-material/Delete";
import { createPortal } from "react-dom";
import { RotateSpinner } from "react-spinners-kit";
import logo from "../../../src/Assets/Images/applnx.png";

const MasterDomain = ({ role, uid }) => {
  const animationContainer = useRef(null);
  const user_uid = uid;
  const [userdata, setUserdata] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [domain, setDomain] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + user_uid,
        "GET"
      );
      if (result.success === true) {
        setUserdata(result.data);
        if (result.data.custom_domains.length === 0) {
          setShowOverlay(false);
        }
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("An Error Occurred In Fetching The Data", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userdata && userdata.custom_domains.length === 0) {
      setShowOverlay(false);
    }
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/50995b21-1c6d-445e-9d68-a3d726e73787/WWp6DtXt26.json",
    });

    return () => {
      lottie.destroy();
    };
  }, [showOverlay]);

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  const handleAddDomain = async () => {
    setLoading(true);
    const data = {
      user_uid: user_uid,
      domain_name: domain,
    };
    try {
      const result = await Controller.ApiController(
        data,
        apiEndPoint.CUSTOM_DOMAIN_USER_ENTRY,
        "POST"
      );

      if (result.success === true) {
        setLoading(false);
        fetchUser();
        enqueueSnackbar(
          "Domain Recived Successfully Kindly Procced With Verification",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        setDomain("");
      } else {
        setLoading(false);
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("An error occurred while adding the domain.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleSwap = () => {
    setShowOverlay(!showOverlay);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleActivation = async (domainId, isActive, domainName) => {
    if (isActive) {
      if (role === "admin") {
        enqueueSnackbar(
          "You do not have permission to deactivate this domain. Conatct Support",
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        return;
      }
    }
    setLoading(true);
    const data = {
      domain_name: domainName,
      user_uid: user_uid,
      doamin_id: domainId,
    };
    try {
      const result = await Controller.ApiController(
        data,
        isActive
          ? `${apiEndPoint.CUSTOM_DOMAIN_DEACTIVATE}`
          : `${apiEndPoint.CUSTOM_DOMAIN_ACTIVATE}`,
        isActive ? "POST" : "POST"
      );

      if (result.success) {
        setLoading(false);
        enqueueSnackbar(
          `Domain ${isActive ? "deactivated" : "activated"} successfully!`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        fetchUser();
      } else {
        setLoading(false);
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("An error occurred while toggling domain status.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleDeleteDialogOpen = (domain) => {
    setDomainToDelete(domain);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    if (domainToDelete) {
      const data = {
        domain_name: domainToDelete.domain_name,
        user_uid: user_uid,
      };
      try {
        const result = await Controller.ApiController(
          data,
          apiEndPoint.CUSTOM_DOMAIN_DELETE,
          "POST"
        );

        if (result.success) {
          setLoading(false);
          enqueueSnackbar("Domain deleted successfully!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          fetchUser();
          if (userdata && userdata.custom_domains.length === 0) {
            setShowOverlay(false);
          }
        } else {
          setLoading(false);
          enqueueSnackbar(`${result.data}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("An error occurred while deleting the domain.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } finally {
        setDeleteDialogOpen(false);
        setDomainToDelete(null);
      }
    }
  };

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
    setDomainToDelete(null);
  };

  const loaderOverlay = loading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999,
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt=".."
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40px",
          height: "40px",
        }}
      />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RotateSpinner size={65} thickness={100} color="red" />
      </div>
    </div>
  ) : null;

  return (
    <Grid
      container
      alignItems="center"
      sx={{ height: "80vh", overflow: "hidden" }}
    >
      {userdata &&
        userdata.custom_domains &&
        userdata.custom_domains.length > 0 && (
          <Button
            variant="contained"
            className={showOverlay ? "greeny" : "orangy"}
            onClick={handleSwap}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              zIndex: 10,
            }}
          >
            <SwapHorizIcon />
            {showOverlay ? "New" : "Verify"}
          </Button>
        )}

      <AnimatePresence initial={false}>
        {!showOverlay && (
          <motion.div
            key="original"
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ width: "90%", height: "100%", position: "absolute" }}
          >
            <Grid container alignItems="center" style={{ height: "100%" }}>
              <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
                <div
                  ref={animationContainer}
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="column"
                sx={{
                  display: "flex",
                  height: "100%",
                  padding: 2,
                }}
              >
                <Grid
                  item
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "30px",
                  }}
                >
                  <Typography sx={{ fontWeight: "800", fontSize: "30px" }}>
                    Use Your Own Domain For the Brandings
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  mt={20}
                  sx={{ flexGrow: 1, width: "100%", mt: { xs: 5, sm: 20 } }}
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      sx={{ fontWeight: "800", fontSize: "20px" }}
                    >
                      Provide Your Domain And Start Your Journey
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      placeholder="Enter your domain..."
                      fullWidth
                      value={domain}
                      onChange={handleDomainChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddDomain}>
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </motion.div>
        )}

        {showOverlay && (
          <motion.div
            key="overlay"
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              width: "90%",
              height: "100%",
              position: "absolute",
            }}
          >
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={12}>
                <Grid mt={7}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "red",
                      textTransform: "uppercase",
                    }}
                  >
                    Make Sure to Add Lb Value To Your Cname Value Of Your Domain
                  </Typography>
                </Grid>
                <TableContainer sx={{ marginTop: "20px" }} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Sno
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Domain Name
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Lb Value
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Created At
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Activated At
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Deactivated At
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userdata?.custom_domains
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((domain, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{ fontWeight: "bolder", textAlign: "center" }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {domain.domain_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {domain.is_active ? "Active" : "Inactive"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {domain.lb_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {new Date(domain.created_at).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {domain.activated_at
                                ? new Date(domain.activated_at).toLocaleString()
                                : "-"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {domain.deactivated_at
                                ? new Date(
                                    domain.deactivated_at
                                  ).toLocaleString()
                                : "-"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                sx={{ marginTop: "10px" }}
                                className={
                                  domain.is_active ? "orangy" : "greeny"
                                }
                                onClick={() =>
                                  handleToggleActivation(
                                    domain.domain_id,
                                    domain.is_active,
                                    domain.domain_name
                                  )
                                }
                              >
                                {domain.is_active ? "Deactivate" : "Activate"}
                              </Button>
                              {!domain.is_active && (
                                <Tooltip title="Remove Entry" placement="right">
                                  <MUIIconButton
                                    className="pinky"
                                    onClick={() =>
                                      handleDeleteDialogOpen(domain)
                                    }
                                    sx={{
                                      marginLeft: 1,
                                      marginTop: "10px",
                                      color: "white",
                                    }}
                                  >
                                    <DeleteIcon />
                                  </MUIIconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[3]}
                  component="div"
                  count={userdata?.custom_domains.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this domain?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            className="orangy"
            sx={{ color: "white" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            className="redy"
            sx={{ color: "white" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {createPortal(loaderOverlay, document.body)}
    </Grid>
  );
};

export default MasterDomain;
