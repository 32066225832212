import React, { useState, useRef, useEffect } from "react";
import { Dashboard as DashboardIcon, Email } from "@mui/icons-material";
import CustomerIcon from "@mui/icons-material/Person";
import "./admin.css";
import {
  Tooltip,
  Container,
  Grid,
  Typography,
  Modal,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import logo from "../../../src/Assets/Images/applnx.png";
import logo2 from "../../../src/Assets/Images/headerlogo.png";
import AdminDashBoard from "./Dashboard/AdminDashboard";
import SuperAdminDashboard from "./Dashboard/SuperAdminDashboard";
import AdminApplnxCreation from "./ApplnxCreation/AdminApplnxCreation";
import SuperAdminApplnxCreation from "./ApplnxCreation/SuperAdminApplnxCreation";
import AdminRechargeHistory from "./RechargeHistory/AdminRechargeHistory";
import SuperadminRechargeHistory from "./RechargeHistory/SuperadminRechargeHistory";
import SuperAdminPurchase from "./Purchase/SuperAdminPurchase";
import Support from "./Support/Support";
import AdminDomain from "./CustomDomain/AdminDomain";
import SuperAdminDomain from "./CustomDomain/SuperAdminDomain";
import TemplateMain from "../EmailTemplateCreator/TemplateMain";
import AdminPurchase from "./Purchase/AdminPurchase";
import AddLinkIcon from "@mui/icons-material/AddLink";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ApiIcon from "@mui/icons-material/Api";
import AdminApiIntegration from "./ApiIntegration/AdminApiIntegration";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DnsIcon from "@mui/icons-material/Dns";
import { SupportAgent as SupportAgentIcon } from "@mui/icons-material";
import PaidIcon from "@mui/icons-material/Paid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
import { RotateSpinner } from "react-spinners-kit";
import { useSnackbar } from "notistack";

import Controller from "../../../src/Service/ApiController";
import { apiEndPoint } from "../../../src/Service/ApiConstant";

const Admin = ({ role, support_email_id }) => {
  const DashboardComponent =
    role === "admin" ? AdminDashBoard : SuperAdminDashboard;

  const [closeMenu, setCloseMenu] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(
    <DashboardComponent />
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [supportUser, setSupportUser] = useState(false);
  const [supportUserRole, setSupportUserRole] = useState('');
  const [supportRole, setSupportRole] = useState('');

  const username = sessionStorage.getItem("UserName");
  const email_id = sessionStorage.getItem("email_id");
  const uid = sessionStorage.getItem("userUid");
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email_id");
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const handleIconClick = (index, component) => {
    setActiveIndex(index);
    setSelectedComponent(component);
    setLoading(true);

    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleLogout = async () => {
    setIsConfirmationOpen(true);
  };

  const handleLogoutConfirmed = async () => {
    setIsConfirmationOpen(false);
    try {
      const response = await axios.post(`${BASE_URL}/logout/${email_id}`, {
        headers: {
          Token: `${token}`,
          email_id: email,
        },
      });

      if (response.data.message === "Logged Out Successfully") {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleCancelLogout = () => {
    setIsConfirmationOpen(false);
  };

  const navigationItems = {
    admin: [
      {
        icon: <DashboardIcon />,
        label: "Dashboard",
        component: <AdminDashBoard />,
      },
      {
        icon: <AddLinkIcon />,
        label: "Link Creation",
        component: <AdminApplnxCreation role={role} />,
      },
      {
        icon: <PaidIcon />,
        label: "Transaction History",
        component: <AdminRechargeHistory role={role} />,
      },
      {
        icon: <ShoppingCartIcon />,
        label: "Purchase",
        component: <AdminPurchase role={role} />,
      },
      {
        icon: <ApiIcon />,
        label: "Integration",
        component: <AdminApiIntegration role={role} />,
      },
      {
        icon: <DnsIcon />,
        label: "Custom Domain",
        component: <AdminDomain role={role} />,
      },
    ],
    superadmin: [
      {
        icon: <DashboardIcon />,
        label: "Dashboard",
        component: <SuperAdminDashboard />,
      },
      {
        icon: <AddLinkIcon />,
        label: "Link Creation",
        component: <SuperAdminApplnxCreation role={role} />,
      },
      {
        icon: <PaidIcon />,
        label: "Transaction History",
        component: <SuperadminRechargeHistory role={role} />,
      },
      {
        icon: <ShoppingCartIcon />,
        label: "Manage Plans",
        component: <SuperAdminPurchase role={role} />,
      },
      {
        icon: <DnsIcon />,
        label: "Domains Management",
        component: <SuperAdminDomain role={role} />,
      },
      {
        icon: <Email />,
        label: "Email Template Management",
        component: <TemplateMain role={role} />,
      },
      (supportRole.includes('Support_Super_Admin') || supportUser) && {
        icon: <SupportAgentIcon />,
        label: "Support Management",
        component: <Support role={supportRole} supportUserRole={supportUserRole} support_email_id={support_email_id} />,
      },
    ].filter(Boolean),
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && document.fullscreenElement) {
        document.exitFullscreen();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchUsers = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        `${apiEndPoint.SUPPORT_GET_ROLE}/${support_email_id}`,
        "GET"
      );
      if (result.success === true) {
        setSupportUser(true);
        setSupportUserRole(result.data.roles);
      } else {
        setSupportUser(false);
      }
    } catch (error) {
      console.error("Support Mail Role Error", error);
    }
  };

  const fetchAction = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + uid,
        "GET",
        {
          params: {},
        }
      );
      if (result.success === true) {
        const companyLevelPermissions =
          result.data.company_level_permissions || [];
          setSupportRole(companyLevelPermissions);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUsers();
    fetchAction();
  }, [support_email_id]);

  return (
    <>
      <Grid id="admin">
        <Grid className="topNavbar">
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <Grid>
              <Typography
                className="welcomeMessage"
                style={{
                  marginLeft: "40px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Welcome, {username}!👋
              </Typography>
            </Grid>
            <Grid>
              <img
                src={logo2}
                alt="icon"
                className="profile"
                style={{ height: "40px" }}
              />
              <Tooltip title="FullScreen Mode" placement="bottom">
                <IconButton onClick={toggleFullScreen}>
                  {document.fullscreenElement ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={closeMenu === false ? "sidebar" : "sidebar active"}>
          <Grid
            sx={{
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid>
              {closeMenu === false && (
                <img
                  src={logo}
                  alt="icon"
                  className="logo"
                  style={{ height: "45px" }}
                />
              )}
            </Grid>
            {closeMenu === false && (
              <Grid
                sx={{
                  marginLeft: "4px",
                  color: "white",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                appLnx.io
              </Grid>
            )}
          </Grid>

          <Grid
            className={
              closeMenu === false ? "burgerContainer" : "burgerContainer_active"
            }
          >
            <Grid
              className="burgerTrigger"
              onClick={() => {
                handleCloseMenu();
              }}
            ></Grid>
            <Grid className="burgerMenu"></Grid>
          </Grid>

          <Grid
            className={
              closeMenu === false
                ? "contentsContainer"
                : "contentsContainer_active"
            }
          >
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Grid sx={{ height: "80vh" }}>
                <ul>
                  {navigationItems[role].map((item, index) => (
                    <li
                      key={index}
                      className={activeIndex === index ? "active-link" : ""}
                      onClick={() => handleIconClick(index, item.component)}
                    >
                      <Tooltip title={item.label} placement="right">
                        {item.icon}
                      </Tooltip>
                      <p>{item.label}</p>
                    </li>
                  ))}
                </ul>
              </Grid>

              <Grid
                container
                alignItems="center"
                onClick={handleLogout}
                className="orangy"
                sx={{ borderRadius: "0.5rem", cursor: "pointer" }}
              >
                <Grid item>
                  <Tooltip title="Logout" placement="right">
                    <IconButton
                      onClick={handleLogout}
                      sx={{ color: "white", marginLeft: "-5px" }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {!closeMenu && (
                  <Grid item>
                    <Typography
                      variant="button"
                      color={"white"}
                      sx={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Logout
                    </Typography>
                  </Grid>
                )}
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Container
        maxWidth="lg"
        className={closeMenu ? "containerOpen" : "containerClosed"}
        ref={containerRef}
      >
        <Grid>{selectedComponent}</Grid>
      </Container>

      <Modal
        open={isConfirmationOpen}
        onClose={handleCancelLogout}
        aria-labelledby="logout-confirmation-dialog-title"
        aria-describedby="logout-confirmation-dialog-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 20,
            width: isSmallScreen ? "80%" : "auto",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Typography variant="h6" id="logout-confirmation-dialog-title">
            Are you sure you want to logout?
          </Typography>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Grid>
              <Button onClick={handleCancelLogout}>Cancel</Button>
            </Grid>
            <Grid>
              <Button onClick={handleLogoutConfirmed}>Logout</Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <img
            src={logo}
            alt=".."
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "40px",
            }}
          />
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <RotateSpinner size={65} thickness={100} color="red" />
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
