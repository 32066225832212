import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Button,
  Divider,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  TextField as MuiTextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QRCodeIcon from "@mui/icons-material/QrCode";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_URL } from "../../config";
import { RotateSpinner } from "react-spinners-kit";
import Controller from "../../Service/ApiController";
import { apiEndPoint } from "../../Service/ApiConstant";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import logo from "../../../src/Assets/Images/applnx.png";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const ShareModal = ({
  open,
  handleClose,
  shortLink,
  fullLink,
  short_code,
  qrDetails,
  loadData,
}) => {
  const data = qrDetails;
  const user_uid = sessionStorage.getItem("userUid");
  const { enqueueSnackbar } = useSnackbar();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [userdata, setUserdata] = useState();
  const [loading, setLoading] = useState(false);
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false);
  const [qrCodeData, setQRCodeData] = useState("");
  const [CodeData, setCodeData] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const setImage = useCallback(async () => {
    if (data) {
      setImgSrc(data);
    } else {
      setImgSrc("");
    }
  }, [data]);

  useEffect(() => {
    setImage();
  }, [setImage]);

  const setCode = useCallback(async () => {
    if (short_code) {
      setCodeData(short_code);
    } else {
      setCodeData("");
    }
  }, [short_code]);

  useEffect(() => {
    setCode();
  }, [setCode]);

  useEffect(() => {
    const fetchLogoBase64 = async () => {
      try {
        const response = await fetch(logo);
        const blob = await response.blob();
        const base64String = await convertToBase64(blob);
        setLogoBase64(base64String);
      } catch (error) {
        console.error("Error converting logo to base64:", error);
      }
    };
    fetchLogoBase64();
  }, []);

  const [emailData, setEmailData] = useState({
    to: [],
    cc: [],
    subject: "",
    body: "",
  });

  useEffect(() => {
    setEmailData((prevData) => ({
      ...prevData,
      body: `Here Is The Shortened URL: <a href="${shortLink}">Click here</a>`,
    }));
  }, [shortLink]);

  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + user_uid,
        "GET"
      );
      if (result.success === true) {
        setUserdata(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  const handleQRCodeModalOpen = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/generate_qr`, {
        link: shortLink,
        logo: logoBase64,
        user_uid: user_uid,
        short_code: CodeData,
      });
      await loadData();
      setQRCodeData(response.data.qrCode);
      setQRCodeModalOpen(true);
    } catch (error) {
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const resetEmailData = () => {
    setEmailData({
      to: [],
      cc: [],
      subject: "",
      body: `Here Is The Shortened URL: <a href="${shortLink}">Click here</a>`,
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar("Copied successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    });
  };

  const handleEmailSend = async () => {
    setLoading(true);
    const customer_id = sessionStorage.getItem("userUid");
    try {
      await axios.post(`${BASE_URL}/share_link`, {
        to: emailData.to.join(","),
        cc: emailData.cc.join(","),
        subject: emailData.subject,
        body: emailData.body,
        customer_id: customer_id,
      });
      enqueueSnackbar("Email sent successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setLoading(false);
      setEmailModalOpen(false);
      resetEmailData();
    } catch (error) {
      setLoading(false);
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleAddEmail = (field, email) => {
    if (emailRegex.test(email) && !emailData[field].includes(email)) {
      setEmailData({ ...emailData, [field]: [...emailData[field], email] });
    } else if (!emailRegex.test(email)) {
      enqueueSnackbar("Invalid email address", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleDeleteEmail = (field, email) => {
    setEmailData({
      ...emailData,
      [field]: emailData[field].filter((e) => e !== email),
    });
  };

  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEmail(field, e.target.value);
      e.target.value = "";
    }
  };

  const handleModalClose = () => {
    resetEmailData();
    handleClose();
  };

  const downloadQRCode = (dataURL) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `${CodeData}_qrcode.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    maxHeight: "90vh",
    display: "flex",
    flexDirection: "column",
  };

  const headerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "8px 16px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    zIndex: 1,
  };

  const bodyStyle = {
    overflowY: "auto",
    padding: "40px 6px 10px 6px", // adjust padding to make space for header and footer
  };

  const footerStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: "8px 16px",
    background: "white",
    zIndex: 1,
  };

  const loader = (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RotateSpinner size={50} thickness={10} color="white" />
    </div>
  );

  return (
    <>
      {loading && ReactDOM.createPortal(loader, document.body)}

      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box sx={headerStyle} className="orangy">
            <Typography id="modal-title" variant="h6" component="h2">
              Share Url
            </Typography>
            <IconButton onClick={handleModalClose} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={bodyStyle}>
            <Box sx={{ p: 4 }}>
              <Typography
                variant="subtitle1"
                component="p"
                sx={{ fontSize: "24px", fontWeight: "600" }}
              >
                Short Link
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={shortLink}
                  InputProps={{ readOnly: true }}
                  margin="normal"
                />
                <IconButton onClick={() => handleCopy(shortLink)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              <Divider style={{ backgroundColor: "grey", margin: "10px 0" }} />
              <Typography
                variant="subtitle1"
                component="p"
                sx={{ fontSize: "24px", fontWeight: "600" }}
              >
                Original Link
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={fullLink}
                  InputProps={{ readOnly: true }}
                  margin="normal"
                />
                <IconButton onClick={() => handleCopy(fullLink)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>

              <>
                <Divider
                  style={{ backgroundColor: "grey", margin: "10px 0" }}
                />

                <Grid>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontSize: "24px", fontWeight: "600" }}
                  >
                    Advanced Sharing
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 2,
                      cursor: "pointer",
                    }}
                  >
                    <IconButton
                      variant="outlined"
                      onClick={() => setEmailModalOpen(true)}
                      sx={{
                        borderColor: "primary.main",
                        color: "green",
                        "&:hover": {
                          borderColor: "primary.dark",
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                        },
                      }}
                    >
                      <EmailIcon />
                    </IconButton>
                    <Typography
                      onClick={() => setEmailModalOpen(true)}
                      variant="button"
                      sx={{
                        marginLeft: 1,
                        fontWeight: "bold",
                        color: "green",
                        cursor: "pointer",
                      }}
                    >
                      Via Email
                    </Typography>
                    <IconButton
                      variant="outlined"
                      onClick={handleQRCodeModalOpen}
                      sx={{
                        borderColor: "primary.main",
                        marginLeft: "10px",
                        color: "blue",
                        "&:hover": {
                          borderColor: "primary.dark",
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                        },
                      }}
                    >
                      <QRCodeIcon />
                    </IconButton>
                    <Typography
                      onClick={handleQRCodeModalOpen}
                      variant="button"
                      sx={{
                        marginLeft: 1,
                        fontWeight: "bold",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      QR Code
                    </Typography>
                  </Box>
                </Grid>
              </>

              <Divider style={{ backgroundColor: "grey", margin: "10px 0" }} />
              <>
                {imgSrc && (
                  <Grid container>
                    <Grid item sm={11}>
                      <img
                        src={imgSrc}
                        alt="QR Code 1"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Grid>
                          <IconButton
                            variant="outlined"
                            onClick={() => downloadQRCode(imgSrc)}
                            className="orangy"
                            sx={{
                              borderColor: "primary.main",
                              marginLeft: "10px",
                              color: "white",
                              "&:hover": {
                                borderColor: "primary.dark",
                                backgroundColor: "rgba(0, 0, 0, 0.08)",
                              },
                            }}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
              <Divider style={{ backgroundColor: "grey", margin: "10px 0" }} />
            </Box>
          </Box>
          <Box sx={footerStyle}>
            <Grid container justifyContent="center" marginTop={2}>
              <Button
                variant="contained"
                color="error"
                onClick={handleModalClose}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <span style={{ fontWeight: "bold" }}> Applnx Sharing</span>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setEmailModalOpen(false)}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            <MuiTextField
              label="To"
              fullWidth
              margin="normal"
              onKeyDown={(e) => handleKeyDown(e, "to")}
            />
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
              {emailData.to.map((email) => (
                <Chip
                  style={{ marginBottom: "8px" }}
                  key={email}
                  label={email}
                  onDelete={() => handleDeleteEmail("to", email)}
                />
              ))}
            </Stack>

            <MuiTextField
              label="CC"
              fullWidth
              margin="normal"
              onKeyDown={(e) => handleKeyDown(e, "cc")}
            />
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
              {emailData.cc.map((email) => (
                <Chip
                  style={{ marginBottom: "8px" }}
                  key={email}
                  label={email}
                  onDelete={() => handleDeleteEmail("cc", email)}
                />
              ))}
            </Stack>

            <MuiTextField
              label="Subject"
              fullWidth
              margin="normal"
              value={emailData.subject}
              onChange={(e) =>
                setEmailData({ ...emailData, subject: e.target.value })
              }
            />
            <ReactQuill
              theme="snow"
              value={emailData.body}
              onChange={(content) =>
                setEmailData({ ...emailData, body: content })
              }
              style={{ height: "300px" }}
              modules={quillModules}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailSend} variant="contained" color="primary">
            Send
          </Button>
          <Button
            onClick={() => setEmailModalOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={qrCodeModalOpen}
        onClose={() => {
          handleClose();
          setQRCodeModalOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <Grid textAlign={"center"}>
          {" "}
          <DialogTitle
            sx={{ fontWeight: "900", padding: "22px 22px 0px 22px" }}
          >
            {" "}
            QR Code
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Grid container>
            <Grid item sm={9}>
              <img
                src={qrCodeData}
                alt="QR Code"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid
              item
              sm={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid>
                  <Button
                    variant="contained"
                    className="orangy"
                    onClick={() => downloadQRCode(qrCodeData)}
                  >
                    Download
                  </Button>
                </Grid>
                <Grid mt={3}>
                  <Button
                    onClick={() => {
                      handleClose();
                      setQRCodeModalOpen(false);
                    }}
                    className="orangy"
                    variant="contained"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["link"],
    [{ color: [] }, { background: [] }],
    ["clean"],
  ],
};

export default ShareModal;
