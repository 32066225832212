import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Paper, Grid } from "@mui/material";
import Stripe from "./Stripe/Stripe";
import PayPal from "./Paypal/Paypal";
import RazorPayment from "./RazorPay/RazorPayment";
import lottie from "lottie-web";
import { RotateSpinner } from "react-spinners-kit";
import logo from "../../../../src/Assets/Images/applnx.png";
import { createPortal } from "react-dom";

const Payment = ({
  exchangeRate,
  region,
  currencySymbol,
  currency,
  plan,
  paymentType,
  onCancel,
}) => {
  const [countdown, setCountdown] = useState(null);
  const animationContainer = useRef(null);
  const [loading, setLoading] = useState(true);

  const convertCurrency = (amount, rate) => (amount * rate).toFixed(2);
  const localizedPrice = convertCurrency(plan.basicDetails.price, exchangeRate);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer;
    if (countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            onCancel();
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown, onCancel]);

  const handleSuccess = () => {
    setCountdown(5);
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/7aae62e3-b2d8-4525-a695-1315fdac2b07/2wv5OqZPlo.json",
    });
  }, []);

  const loaderOverlay = loading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999,
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt=".."
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40px",
          height: "40px",
        }}
      />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RotateSpinner size={65} thickness={100} color="red" />
      </div>
    </div>
  ) : null;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Paper sx={{ padding: "1rem", width: "100%", textAlign: "center" }}>
          <Typography variant="h4" component="h4" sx={{ marginBottom: "2rem" }}>
            Payment for {plan.basicDetails.title}
          </Typography>
          <Typography variant="h5" component="h5" sx={{ marginBottom: "2rem" }}>
            Amount: {currencySymbol} {localizedPrice}
          </Typography>
          {countdown !== null ? (
            <Typography
              variant="h6"
              component="h6"
              sx={{ marginBottom: "2rem" }}
            >
              Payment completed successfully! Redirecting in {countdown}{" "}
              seconds...
            </Typography>
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    ref={animationContainer}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {paymentType === "PayPal" ? (
                    <PayPal
                      amount={localizedPrice}
                      currencySymbol={currencySymbol}
                      id={plan.plan_id}
                      onSuccess={handleSuccess}
                    />
                  ) : paymentType === "Razorpay" ? (
                    <RazorPayment
                      amount={localizedPrice}
                      currencySymbol={currencySymbol}
                      id={plan.plan_id}
                      onSuccess={handleSuccess}
                    />
                  ) : paymentType === "Stripe" ? (
                    <Stripe
                      amount={localizedPrice}
                      currencySymbol={currencySymbol}
                      id={plan.plan_id}
                      onSuccess={handleSuccess}
                      onCancel={onCancel}
                      region={region}
                      currency={currency}
                    />
                  ) : (
                    "Invalid Payment Type"
                  )}
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="redy"
                  size="medium"
                  sx={{
                    marginTop: "0.8rem",
                    marginBottom: "0.5rem",
                    alignSelf: "center",
                  }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      {createPortal(loaderOverlay, document.body)}
    </>
  );
};

export default Payment;
