import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Container, Grid, Paper, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import Controller from "../../../../Service/ApiController";
import { apiEndPoint } from "../../../../Service/ApiConstant";

const PayPal = ({ amount, currencySymbol, id, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const user_id = sessionStorage.getItem("userUid");
  const handleApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      try {
        await Controller.ApiController(
          {
            transactionId: details.id,
            full_details: details,
            amount: currencySymbol + amount,
            status: "completed",
            payment_type: "Paypal",
            payment_id: id,
            user_id: user_id,
          },
          apiEndPoint.PAYMENT,
          "POST"
        );
        enqueueSnackbar("Payment completed successfully!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        onSuccess();
      } catch (axiosError) {
        enqueueSnackbar(
          "Payment completed, but there was an issue with recording the payment. Please contact support.",
          {
            variant: "info",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    } catch (paypalError) {
      enqueueSnackbar("Payment failed. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      try {
        const details = await actions.order.capture();
        await Controller.ApiController(
          {
            transactionId: data.orderID,
            amount: currencySymbol + amount,  
            full_details: details,
            status: "error",
            payment_type: "Paypal",
            payment_id: id,
            user_id: user_id,
            errorMessage: paypalError.message,
          },
          apiEndPoint.PAYMENT,
          "POST"
        );
      } catch (axiosError) {
        enqueueSnackbar(
          "Payment failed and there was an issue recording the failed payment. Please contact support.",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    }
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <Paper elevation={3}>
              <Box p={3}>
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: parseFloat(amount.replace(/[^0-9.-]+/g, "")),
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={handleApprove}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </PayPalScriptProvider>
  );
};

export default PayPal;
