import React from 'react';
import { Button, CircularProgress,Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const UploadButton = ({ isUploading, isCompleted, handleClick }) => {
  const buttonClassName = isUploading ? 'orangy' : isCompleted ? 'greeny' : 'bluey';

  return (
    <>
    <Grid sx={{display:'flex',alignItems:"center",justifyContent:"center"}}> 
    <Button
      variant="contained"
      onClick={handleClick}
      disabled={isUploading || isCompleted}
      className={buttonClassName}
      sx={{
        position: 'relative',
        width: '200px',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        overflow: 'hidden',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: isUploading ? 'orangered' : isCompleted ? 'greenlight' : 'bluehover',
        },
      }}
    >
      {isUploading && (
        <CircularProgress
          size={36} // Increase the size of CircularProgress
          sx={{
            position: 'absolute',
            zIndex: 1,
            color: 'white',
            strokeWidth: 6, 
          }}
        />
      )}
      {isCompleted && (
        <CheckIcon
          sx={{
            position: 'absolute',
            zIndex: 1,
            color: 'white',
            fontSize: 36,
          }}
        />
      )}
      <span
        style={{
          visibility: isUploading || isCompleted ? 'hidden' : 'visible',
        }}
      >
        Submit
      </span>
    </Button>
    </Grid>
    </>
  );
};

export default UploadButton;
