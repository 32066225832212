import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Controller from "../../../../Service/ApiController";
import { apiEndPoint } from "../../../../Service/ApiConstant";
import axios from "axios";
import { BASE_URL } from "../../../../config";

const CheckoutForm = ({
  amount,
  currencySymbol,
  id,
  onSuccess,
  onCancel,
  paymentIntentId,
  email
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const user_id = sessionStorage.getItem("userUid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
          receipt_email: email,
        },
        redirect: "if_required",
      });

      if (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        await axios.post(`${BASE_URL}/stripe/confirmation_payment`, {
          paymentIntentId: paymentIntent.id,
          email: email,
        });
        const result = await Controller.ApiController(
          {
            transactionId: paymentIntent.id,
            full_details: paymentIntent,
            amount: currencySymbol + amount,
            status: "completed",
            payment_type: "Stripe",
            payment_id: id,
            user_id: user_id,
          },
          apiEndPoint.PAYMENT,
          "POST"
        );

        if (result.success) {
          onSuccess();
          enqueueSnackbar("Payment Completed Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        } else {
          enqueueSnackbar(
            "Payment successful, but there was an issue recording the payment. Please contact support.",
            {
              variant: "info",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(
        "Payment successful, but there was an issue recording the payment. Please contact support.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }

    setIsSubmitting(false);
  };

  const handleCancel = async () => {
    try {
      await axios.post(`${BASE_URL}/stripe/cancel_payment`, {
        paymentIntentId,
      });
      enqueueSnackbar("Payment has been canceled", {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      onCancel()
    } catch (error) {
      enqueueSnackbar("Failed to cancel the payment", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    setOpenCancelModal(false);
  };

  const handleCancelButtonClick = () => {
    setOpenCancelModal(true);
  };

  const handleModalClose = () => {
    setOpenCancelModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <AddressElement options={{ mode: "billing" }} />
          <PaymentElement options={{ layout: "tabs" }} />

          <Grid container>
            <Grid item xs={5}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                className="greeny"
                fullWidth
                sx={{ marginTop: "2rem" ,color:"white"}}
                disabled={isSubmitting || !stripe}
                startIcon={isSubmitting ? <CircularProgress size={22} sx={{color:"white"}} /> : null}
              >
                <span style={{color:"white"}}>{isSubmitting ? "Processing..." : `Pay ${currencySymbol}${amount}`}</span>
              </Button>
            </Grid>
            <Grid item xs={2}>
              {" "}
            </Grid>
            <Grid item xs={5}>
              {" "}
              <Button
                type="button"
                variant="outlined"
                size="medium"
                className="pinky"
                fullWidth
                sx={{ marginTop: "2rem", color: "white" }}
                onClick={handleCancelButtonClick}
                disabled={isSubmitting || !stripe}
              >
                  <span style={{color:"white"}}> Cancel Payment</span>
               
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
        open={openCancelModal}
        onClose={handleModalClose}
      >
        <DialogTitle sx={{textAlign:"center",fontWeight:"800"}}>{"Cancel Payment"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color:"black"}}>
            Are you sure you want to cancel the payment? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} className="greeny" sx={{color:"white"}} >
            No
          </Button>
          <Button onClick={handleCancel} className="pinky" sx={{color:"white"}} >
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CheckoutForm;
