import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReactDOMServer from "react-dom/server";
import Controller from "../../Service/ApiController";
import { apiEndPoint } from "../../Service/ApiConstant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatsModal = ({ uid, isModalOpen, handleCloseModal }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [trafficData, setTrafficData] = useState([]);
  const [data, setData] = useState([]);
  const [shortUrl, setShortUrl] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const [clickHistory, setClickHistory] = useState([]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const fetchData = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        `${apiEndPoint.GET_STATS}${uid}`,
        "GET",
        {
          params: {},
        }
      );

      if (result.success) {
        const resultData = result.data?.data || {};
        setData(resultData.overallClicks || []);
        setShortUrl(resultData.short_url || "");
        setFullUrl(resultData.long_url || "");
        setTrafficData(resultData.trafficData || []);
        setClickHistory(resultData.clickHistory || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  const chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        label: "Hits",
        data: data.map((item) => item.hits),
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Rate",
        data: data.map((item) => item.rate),
        fill: false,
        backgroundColor: "rgba(153,102,255,0.4)",
        borderColor: "rgba(153,102,255,1)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Applnx
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          &times;
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box p={3}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} md={5}>
                  <Typography variant="body1">
                    <strong>Short URL: </strong>
                    <a
                      href={shortUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortUrl}
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant="body1">
                    <strong>Long URL: </strong>
                    <a href={fullUrl} target="_blank" rel="noopener noreferrer">
                      {fullUrl}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Traffic statistics" />
                  <Tab label="Traffic location" />
                  <Tab label="Click History" />
                </Tabs>
              </Box>
              <Box mt={3}>
                {tabIndex === 0 && (
                  <div style={{ height: "400px" }}>
                    <Line data={chartData} options={chartOptions} />
                  </div>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <Typography variant="h6">Traffic Location</Typography>
                    <MapContainer
                      center={[20, 0]}
                      zoom={2}
                      style={{ height: "500px", width: "100%" }}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      {trafficData.map((item, index) =>
                        item.latitude && item.longitude ? (
                          <Marker
                            key={index}
                            position={[item.latitude, item.longitude]}
                            icon={L.divIcon({
                              className: "custom-icon",
                              html: ReactDOMServer.renderToString(
                                <IconButton
                                  size="medium"
                                  style={{
                                    backgroundColor: "transparent",
                                    padding: 0,
                                    width: "30px",
                                    border: "0px solid",
                                  }}
                                >
                                  <LocationOnIcon
                                    style={{ fontSize: 25, color: "black" }}
                                  />
                                </IconButton>
                              ),
                              iconSize: [40, 40],
                              iconAnchor: [20, 40],
                            })}
                          >
                            <Popup>
                              {item.region}: {item.count} hits
                            </Popup>
                          </Marker>
                        ) : (
                          <Grid> Invalid IP and location</Grid>
                        )
                      )}
                    </MapContainer>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            S.No
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Device Type
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Browser Name
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Clicked Date / Time
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>IP</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Region
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            City
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clickHistory.map((click, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{click.device_type}</TableCell>
                            <TableCell>{click.browser_name}</TableCell>
                            <TableCell>
                              {new Date(
                                click.clicked_date
                              ).toLocaleDateString()}
                              ,
                              {new Date(
                                click.clicked_date
                              ).toLocaleTimeString()}
                            </TableCell>
                            <TableCell>{click.location.ip}</TableCell>
                            <TableCell>{click.location.region}</TableCell>
                            <TableCell>{click.location.city}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StatsModal;
