import React from "react";
import MasterDomain from "../../MasterDomain/MasterDomain";

const AdminDomain = ({ role }) => {
  const uid = sessionStorage.getItem("userUid");
  return (
    <div>
      <MasterDomain role={role} uid={uid} />
    </div>
  );
};

export default AdminDomain;