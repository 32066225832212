import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import { Star as StarIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const EndChatModal = ({ ticket, endChatHandler }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [hoverRating, setHoverRating] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setRating(0);
    setComment("");
    setModalIsOpen(false);
  };

  const handleSubmit = () => {
    if (rating === 0) {
      enqueueSnackbar(`Please provide a star rating.`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    const feedback = {
      rating,
      comment,
    };

    endChatHandler(ticket.ticket_id, feedback);
    setRating(0);
    setComment("");
    enqueueSnackbar(`Feedback Submitted SuccessFully And Ticket Is Closed`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    closeModal();
  };

  return (
    <>
      <Button
        variant="contained"
        className="redy"
        size="small"
        onClick={openModal}
        sx={{ fontSize: "12px" }}
      >
        End Chat
      </Button>

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            Rate Our Service
          </Typography>

          <Rating
            name="service-rating"
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
            onChangeActive={(event, newHover) => setHoverRating(newHover)}
            precision={1}
            icon={<StarIcon fontSize="inherit" style={{ fontSize: "55px" }} />}
            emptyIcon={
              <StarIcon
                style={{ color: "#ccc", fontSize: "55px" }}
                fontSize="inherit"
              />
            }
            sx={{
              "& .MuiRating-iconFilled": {
                color: (() => {
                  const displayRating =
                    hoverRating !== -1 ? hoverRating : rating;
                  switch (displayRating) {
                    case 1:
                      return "#ff4444"; 
                    case 2:
                      return "#ff8800"; 
                    case 3:
                      return "#1689ff"; 
                    case 4:
                      return "#99cc00"; 
                    case 5:
                      return "#00c851"; 
                    default:
                      return "orange";
                  }
                })(),
              },
              "& .MuiRating-iconHover": {
                color: (() => {
                  switch (hoverRating) {
                    case 1:
                      return "#ff4444"; 
                    case 2:
                      return "#ff8800"; 
                    case 3:
                      return "#1689ff"; 
                    case 4:
                      return "#99cc00"; 
                    case 5:
                      return "#00c851";
                    default:
                      return "orange"; 
                  }
                })(),
              },
            }}
          />

          <TextField
            label="Leave a comment (optional)"
            multiline
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              className="greeny"
              onClick={handleSubmit}
            >
              Submit & Close Ticket
            </Button>
            <Button
              variant="outlined"
              className="redy"
              sx={{ color: "white" }}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EndChatModal;
