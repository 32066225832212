import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
  styled,
  Box,
  TablePagination,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../config";
import Loader from "../Modals/Loader";


const TemplateList = ({ onEditTemplate }) => {
  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/email/templates/get/data`);
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleview = async (templateId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/email/templates/get/data/${templateId}`
      );
      setSelectedTemplate(response.data);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTemplate(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (template) => {
    onEditTemplate(template);
  };

  const handleDelete = async (templateId) => {
    if (window.confirm("Are you sure you want to delete this template?")) {
      try {
        await axios.delete(
          `${BASE_URL}/email/templates/delete/data/${templateId}`
        );
        fetchTemplates(); 
      } catch (error) {
        console.error("Error deleting template:", error);
      }
    }
  };

  return (
    <>
      <Loader timing={2000} />
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{ marginTop: "10px" }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ p: 2 }}
          textAlign={"center"}
        >
          Email Templates
        </Typography>
        <Divider sx={{border:"1px solid black"}} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                SNO
              </TableCell>
              <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                Template Name
              </TableCell>
              <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                Created At
              </TableCell>
              <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {templates
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((template, index) => (
                <TableRow
                  key={template._id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "white" : "grey.200",
                  }}
                >
                  <TableCell  sx={{ fontWeight: 800, textAlign: "center" }}>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell  sx={{ textAlign: "center" }}>{template.templateName}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {new Date(template.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center"}} >
                    <IconButton
                      onClick={() => handleview(template._id)}
                      color="primary"
                      className="orangy"
                      sx={{marginRight:"5px"}}
                    >
                      <VisibilityIcon  sx={{color:"white"}}/>
                    </IconButton>
                    <IconButton
                      onClick={() => handleEdit(template)}
                      color="secondary"
                        className="bluey"
                        sx={{marginRight:"5px"}}
                    >
                      <EditIcon sx={{color:"white"}} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(template._id)}
                      color="error"
                        className="redy"
                        sx={{marginRight:"5px"}}
                    >
                      <DeleteIcon sx={{color:"white"}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>View Template</DialogTitle>
        <DialogContent>
          {selectedTemplate?.templateData?.html ? (
            <div
              dangerouslySetInnerHTML={{
                __html: selectedTemplate.templateData.html,
              }}
            />
          ) : (
            <Typography variant="body2">
              {selectedTemplate?.templateData?.text ||
                "No template data available"}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplateList;
