import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Collapse,
  Tooltip,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Pagination,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Link,
  FormHelperText,
  Checkbox,
  IconButton,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Controller from "../../Service/ApiController";
import { apiEndPoint } from "../../Service/ApiConstant";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ShareModal from "../Modals/ShareModal";
import StatsModal from "../Modals/StatsModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import moment from "moment";
import FolderManagement from "../Modals/FolderManagement";
import FolderFilterModal from "../Modals/FolderFilterModal";
import { keyframes } from "@emotion/react";
import axios from "axios";
import { BASE_URL } from "../../config";
import "./creation.css";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ApplnxCreation = ({ role, uid }) => {
  const user_uid = sessionStorage.getItem("userUid");
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ search: "" });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userdata, setUserdata] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [customFolderOpen, setCustomFolderOpen] = useState(false);
  const [customDomainOpen, setCustomDomainOpen] = useState(false);
  const [linkValidityOpen, setLinkValidityOpen] = useState(false);
  const [passwordProtectionOpen, setPasswordProtection] = useState(false);
  const [Protectedemails, SetProtectederrorEmails] = useState();
  const [selectedFolders, setSelectedFolders] = useState(["All"]);
  const [folderFilterOpen, setFolderFilterOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [domains, setDomains] = useState();
  const [open, setOpen] = useState(false);
  const [statsopen, setstatsOpen] = useState(false);
  const [shortLink, SetShortLink] = useState();
  const [shortCode, SetShortCode] = useState();
  const [qrDetails, SetQrDetails] = useState();
  const [fullLink, SetfullLink] = useState();
  const [statsuid, SetStatsuid] = useState();
  const [selected, setSelected] = useState([]);
  const [customRegionOpen, setCustomRegionOpen] = useState(false);
  const [customRegionError, setCustomRegionError] = useState([]);
  const [customCountryError, setCustomCountryError] = useState([]);
  const [showCustomRegion, setShowCustomRegion] = useState(false);
  const [regions, setRegions] = useState([
    {
      country: "",
      regions: [],
      showCountrySelect: false,
      showRegionSelect: false,
    },
  ]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((row) => row._id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClose = () => setOpen(false);
  const loadData = () => fetchData();
  const handlestatsClose = () => setstatsOpen(false);

  const validationSchema = Yup.object({
    url: Yup.string().url("Invalid URL").required("URL is required"),
    customDomain: Yup.string().matches(
      /^(?:[\w-]+\.){0,2}(?:[a-zA-Z]{2,})$/,
      "Provide a valid custom domain"
    ),
    expiryDate: Yup.date()
      .min(new Date(), "Expiry Date should be in the future")
      .when("linkValidity", {
        is: "custom",
        then: (schema) => schema.required("Expiry date is required"),
        otherwise: (schema) => schema.nullable(),
      }),
  });
  useEffect(() => {
    fetchData();
  }, [page, filters]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.GET_LINK +
          `/${uid}` +
          `?page=${page}&search=${filters.search}&custom_folder=${selectedFolders}`,
        "GET"
      );
      setData(result.data.data.data);
      setTotalPages(result.data.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClear = () => {
    setFilters({ search: "" });
    fetchData();
  };

  const AccordionClose = () => {
    setSettingsOpen(!settingsOpen);
  };

  const fetchFolders = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/folders/get_folder/${uid}`);
      setFolders(response.data);
    } catch (error) {
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + user_uid,
        "GET"
      );
      if (result.success === true) {
        const activeDomains = result.data.custom_domains.filter(domain => domain.is_active);
        setUserdata(result.data);
        setDomains(activeDomains)
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchFolders();
  }, []);

  const dataFetchers = () => {
    fetchUser();
    fetchData();
    fetchFolders();
  };

  const calculateTimeDifference = (expiryDate) => {
    if (!expiryDate) return null;
    const currentDate = moment();
    const expiryMoment = moment(expiryDate);
    const difference = expiryMoment.diff(currentDate);
    const duration = moment.duration(difference);
    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  let counter = (page - 1) * 10;

  const truncateUrl = (url, maxLength) => {
    if (url.length <= maxLength) return url;
    return url.slice(0, maxLength) + "...";
  };

  const calculateCountdown = (expiryDate) => {
    const expiry = new Date(expiryDate);
    const now = currentTime;
    const difference = expiry - now;

    if (difference <= 0) return "Expired";

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const handleShareClick = (rowData) => {
    SetShortLink(rowData.short_url);
    SetfullLink(rowData.full_url);
    SetShortCode(rowData.short_code);
    SetQrDetails(rowData.qr_details);
    setOpen(true);
  };
  const handleStatsClick = (rowData) => {
    SetStatsuid(rowData.uid);
    SetShortCode(rowData.short_code);
    setstatsOpen(true);
  };

  const handleAddRegion = () => {
    setRegions([
      ...regions,
      {
        country: "",
        regions: [],
        showCountrySelect: false,
        showRegionSelect: false,
      },
    ]);
    setCustomCountryError([...customCountryError, ""]);
    setCustomRegionError([...customRegionError, ""]);
  };

  const handleRemoveRegion = (index) => {
    if (regions.length > 1) {
      const updatedRegions = regions.filter((_, i) => i !== index);
      setRegions(updatedRegions);

      if (Array.isArray(customCountryError)) {
        setCustomCountryError(customCountryError.filter((_, i) => i !== index));
      }

      if (Array.isArray(customRegionError)) {
        setCustomRegionError(customRegionError.filter((_, i) => i !== index));
      }
    }
  };

  const handleCountryChange = (index, val) => {
    const isCountryDuplicate = regions.some(
      (region, i) => i !== index && region.country === val
    );

    if (isCountryDuplicate) {
      const updatedCountryErrors = [...customCountryError];
      updatedCountryErrors[index] = "Country already selected";
      setCustomCountryError(updatedCountryErrors);
      return;
    }

    const updatedRegions = [...regions];
    updatedRegions[index].country = val;
    updatedRegions[index].regions = updatedRegions[index].regions || []; // Ensure regions is always an array
    setRegions(updatedRegions);

    const updatedCountryErrors = [...customCountryError];
    updatedCountryErrors[index] = "";
    setCustomCountryError(updatedCountryErrors);
  };

  const handleRegionChange = (index, val) => {
    const updatedRegions = [...regions];
    updatedRegions[index].regions = updatedRegions[index].regions || [];

    const regionIndex = updatedRegions[index].regions.indexOf(val);
    if (regionIndex === -1) {
      updatedRegions[index].regions.push(val);
    } else {
      updatedRegions[index].regions.splice(regionIndex, 1);
    }
    setRegions(updatedRegions);

    const updatedRegionErrors = [...customRegionError];
    updatedRegionErrors[index] = "";
    setCustomRegionError(updatedRegionErrors);
  };

  useEffect(() => {
    fetchData(selectedFolders);
  }, [page, filters, selectedFolders]);

  const handleFolderFilterOpen = () => {
    setFolderFilterOpen(true);
  };

  const handleFolderFilterClose = () => {
    setFolderFilterOpen(false);
  };

  const handleFolderFilterApply = (selectedFolders) => {
    setSelectedFolders(selectedFolders);
    setPage(1);
  };

  const setCode = useCallback(async (result) => {
    if (result) {
      SetShortCode(result.data.short_code);
    } else {
      SetShortCode("");
    }
  }, []);
  

  const handleShortenUrl = async (values, { resetForm }) => {
    let expiryDateToSend = null;
    setCustomRegionError("");
    setCustomCountryError("");
    if (values.expiryDate) {
      const data = values.expiryDate;
      const timeDifference = calculateTimeDifference(data);
      expiryDateToSend = ` ${timeDifference.days}days,${timeDifference.hours}hours,${timeDifference.minutes}min,${timeDifference.seconds}sec`;
    }

    if (values.enablePasswordProtection) {
      const emailErrors = {};
      emailErrors.emails = [];
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let hasError = false;

      values.emails.forEach((email, index) => {
        if (!email) {
          emailErrors.emails[index] = "Email is required";
          hasError = true;
        } else if (!emailRegex.test(email)) {
          emailErrors.emails[index] = "Invalid email format";
          hasError = true;
        }
      });

      if (hasError) {
        SetProtectederrorEmails(emailErrors);
        return;
      } else {
        SetProtectederrorEmails({});
      }
    }

    if (showCustomRegion) {
      if (regions.length === 0 || regions.every((region) => !region.country)) {
        enqueueSnackbar(`At least one country is required`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }

      const regionErrors = regions.map((region) => {
        const errors = {};
        if (!region.country) {
          errors.country = "Country is required";
        }
        if (
          region.showRegionSelect &&
          (!region.regions || region.regions.length === 0)
        ) {
          errors.region = "At least one region is required";
        }
        return errors;
      });

      const hasError = regionErrors.some(
        (errors) => errors.country || errors.region
      );

      if (hasError) {
        setCustomRegionError(regionErrors.map((error) => error.region || ""));
        setCustomCountryError(regionErrors.map((error) => error.country || ""));
        return;
      } else {
        setCustomRegionError([]);
        setCustomCountryError([]);
      }
    }

    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.CREATE_LINK +
          `?customer_id=${uid}
          &url=${values.url}
          &customFolder=${values.customFolder}
          &customDomain=${values.customDomain}
          &isPermanent=${values.linkValidity}
          &customExpiry=${expiryDateToSend}
          &iscustomRegion=${showCustomRegion}
          &regions=${JSON.stringify(regions)}  
          &enablePasswordProtection=${values.enablePasswordProtection}
          &emails=${
            values.enablePasswordProtection ? values.emails.join(",") : ""
          }`,
        "POST"
      );
      if (result.success === true) {
        const data = result.data.message;
        const values = result.data.data;
        SetQrDetails("");
        await setCode(result);
        SetShortCode(values.short_code);
        SetShortLink(values.link);
        SetfullLink(values.full_url);
        setOpen(true);
        resetForm();
        setShowCustomRegion(false);
        setPasswordProtection(false);
        setSettingsOpen(false);
        setRegions([
          {
            country: "",
            regions: [],
            showCountrySelect: false,
            showRegionSelect: false,
          },
        ]);
        fetchData();
        enqueueSnackbar(`${data}`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else if (result.success === false) {
        const data = result.data;
        enqueueSnackbar(`${data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar(
          `Unexpected Error. If this error occurs, contact admin.`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(`Server Error`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.error("Error shortening URL:", error);
    }
  };

  const shouldShowAdvanceSetting =
    userdata &&
    (userdata.company_level_permissions.includes("superadmin") ||
      userdata.plan_details.is_folder === true ||
      userdata.plan_details.is_region_restriction === true ||
      userdata.plan_details.is_country_restriction === true ||
      userdata.plan_details.is_password_protected === true ||
      userdata.plan_details.is_custom_domain === true ||
      userdata.plan_details.is_permanent === true) ;

  return (
    <>
      <Grid container fluid style={{ padding: "16px" }}>
        <Paper elevation={3} style={{ padding: "20px", width: "100%" }}>
          <Formik
            initialValues={{
              url: "",
              linkValidity: "",
              customFolder: "Default",
              customDomain: "Default",
              expiryDate: null,
              enablePasswordProtection: false,
              emails: [""],
            }}
            validationSchema={validationSchema}
            onSubmit={handleShortenUrl}
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 800,
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      CREATE NEW SHORTEN URL
                    </Typography>
                    <Divider
                      style={{ backgroundColor: "grey", margin: "10px 0" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignItems: "self-start",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        fullWidth
                        name="url"
                        label="Enter the URL:"
                        placeholder="https://"
                        variant="outlined"
                        value={values.url}
                        onChange={handleChange}
                        error={touched.url && Boolean(errors.url)}
                        helperText={touched.url && errors.url}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Collapse
                  in={shouldShowAdvanceSetting}
                  timeout={2000}
                  sx={{ marginTop: "10px" }}
                >
                  <Accordion
                    className={`settingsAccordion`}
                    expanded={settingsOpen}
                    onChange={() => {
                      setSettingsOpen(!settingsOpen);
                      setCustomFolderOpen(false);
                      setCustomDomainOpen(false);
                      setLinkValidityOpen(false);
                      setCustomRegionOpen(false);
                      setPasswordProtection(false);
                    }}
                    sx={{ borderRadius: "10px !important" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="custom-setting-content"
                      id="custom-setting-header"
                      sx={{ borderRadius: "10px" }}
                      className="pinky"
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: "15px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        Advanced Settings{" "}
                        <SettingsIcon
                          sx={{
                            animation: `${rotate} 2s linear infinite`,
                            marginLeft: "3px",
                          }}
                        />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                          {userdata &&
                            (userdata.company_level_permissions.includes(
                              "superadmin"
                            ) ||
                              userdata.plan_details.is_folder === true) && (
                              <Grid item xs={12} md={12} mt={2}>
                                <Paper sx={{ borderRadius: "10px" }}>
                                  <Accordion
                                    expanded={customFolderOpen}
                                    onChange={() =>
                                      setCustomFolderOpen(!customFolderOpen)
                                    }
                                    sx={{ borderRadius: "10px !important" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="custom-code-content"
                                      id="custom-code-header"
                                      sx={{ borderRadius: "10px" }}
                                      className="bluey"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Custom Folder
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel>Custom Folder</InputLabel>
                                        <Select
                                          name="customFolder"
                                          value={values.customFolder}
                                          onChange={handleChange}
                                          label="Custom Folder"
                                        >
                                          <MenuItem value="Default">
                                            Default
                                          </MenuItem>
                                          {folders.map((folder, index) => (
                                            <MenuItem
                                              key={index}
                                              value={folder.folder_name}
                                            >
                                              {folder.folder_name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                            )}
                          {userdata &&
                            (userdata.company_level_permissions.includes(
                              "superadmin"
                            ) ||
                              userdata.plan_details.is_region_restriction ===
                                true ||
                              userdata.plan_details.is_country_restriction ===
                                true) && (
                              <Grid item xs={12} md={12} mt={2}>
                                <Paper sx={{ borderRadius: "10px" }}>
                                  <Accordion
                                    expanded={customRegionOpen}
                                    onChange={() =>
                                      setCustomRegionOpen(!customRegionOpen)
                                    }
                                    sx={{ borderRadius: "10px !important" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="custom-region-content"
                                      id="custom-region-header"
                                      sx={{ borderRadius: "10px" }}
                                      className="bluey"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Custom Region
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                          <FormControl component="fieldset">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={showCustomRegion}
                                                  onChange={(e) => {
                                                    setShowCustomRegion(
                                                      e.target.checked
                                                    );
                                                    if (!e.target.checked) {
                                                      setRegions([
                                                        {
                                                          country: "",
                                                          regions: [],
                                                          showCountrySelect: false,
                                                          showRegionSelect: false,
                                                        },
                                                      ]);
                                                    }
                                                  }}
                                                />
                                              }
                                              label="Set Custom Location"
                                            />
                                            {showCustomRegion && (
                                              <>
                                                {regions.map(
                                                  (region, index) => (
                                                    <>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                        key={index}
                                                        alignItems="center"
                                                        mt={0.5}
                                                      >
                                                        {userdata &&
                                                          (userdata.company_level_permissions.includes(
                                                            "superadmin"
                                                          ) ||
                                                            userdata
                                                              .plan_details
                                                              .is_country_restriction ===
                                                              true) && (
                                                            <Grid item xs={5}>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      region.showCountrySelect
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const updatedRegions =
                                                                        [
                                                                          ...regions,
                                                                        ];
                                                                      updatedRegions[
                                                                        index
                                                                      ].showCountrySelect =
                                                                        e.target.checked;
                                                                      setRegions(
                                                                        updatedRegions
                                                                      );
                                                                      setCustomCountryError(
                                                                        ""
                                                                      );
                                                                      setCustomRegionError(
                                                                        ""
                                                                      );
                                                                    }}
                                                                  />
                                                                }
                                                                label="Select Country"
                                                              />
                                                              {region.showCountrySelect && (
                                                                <>
                                                                  <CountryDropdown
                                                                    value={
                                                                      region.country
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "10px",
                                                                      borderRadius:
                                                                        "4px",
                                                                      borderColor:
                                                                        "rgba(0, 0, 0, 0.23)",
                                                                      fontSize:
                                                                        "16px",
                                                                    }}
                                                                    onChange={(
                                                                      val
                                                                    ) =>
                                                                      handleCountryChange(
                                                                        index,
                                                                        val
                                                                      )
                                                                    }
                                                                  />
                                                                  {customCountryError[
                                                                    index
                                                                  ] && (
                                                                    <FormHelperText
                                                                      error
                                                                    >
                                                                      {
                                                                        customCountryError[
                                                                          index
                                                                        ]
                                                                      }
                                                                    </FormHelperText>
                                                                  )}
                                                                </>
                                                              )}
                                                            </Grid>
                                                          )}
                                                        {userdata &&
                                                          (userdata.company_level_permissions.includes(
                                                            "superadmin"
                                                          ) ||
                                                            userdata
                                                              .plan_details
                                                              .is_region_restriction ===
                                                              true) && (
                                                            <Grid item xs={5}>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      region.showRegionSelect
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const updatedRegions =
                                                                        [
                                                                          ...regions,
                                                                        ];
                                                                      updatedRegions[
                                                                        index
                                                                      ].showRegionSelect =
                                                                        e.target.checked;
                                                                      setRegions(
                                                                        updatedRegions
                                                                      );
                                                                      setCustomRegionError(
                                                                        ""
                                                                      );
                                                                      setCustomCountryError(
                                                                        ""
                                                                      );
                                                                    }}
                                                                  />
                                                                }
                                                                label="Select Region"
                                                              />
                                                              {region.showRegionSelect && (
                                                                <>
                                                                  <RegionDropdown
                                                                    country={
                                                                      region.country
                                                                    }
                                                                    value=""
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "10px",
                                                                      borderRadius:
                                                                        "4px",
                                                                      borderColor:
                                                                        "rgba(0, 0, 0, 0.23)",
                                                                      fontSize:
                                                                        "16px",
                                                                    }}
                                                                    onChange={(
                                                                      val
                                                                    ) =>
                                                                      handleRegionChange(
                                                                        index,
                                                                        val
                                                                      )
                                                                    }
                                                                  />
                                                                  {customRegionError[
                                                                    index
                                                                  ] && (
                                                                    <FormHelperText
                                                                      error
                                                                    >
                                                                      {
                                                                        customRegionError[
                                                                          index
                                                                        ]
                                                                      }
                                                                    </FormHelperText>
                                                                  )}
                                                                </>
                                                              )}
                                                            </Grid>
                                                          )}

                                                        <Grid item xs={2}>
                                                          <Tooltip title="Remove Location">
                                                            <IconButton
                                                              onClick={() =>
                                                                handleRemoveRegion(
                                                                  index
                                                                )
                                                              }
                                                              disabled={
                                                                regions.length ===
                                                                1
                                                              }
                                                              aria-label="delete"
                                                              size="large"
                                                              color="error"
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </Tooltip>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid mt={1}>
                                                        {region.regions.map(
                                                          (regionName, i) => (
                                                            <Chip
                                                              key={i}
                                                              label={regionName}
                                                              onDelete={() =>
                                                                handleRegionChange(
                                                                  index,
                                                                  regionName
                                                                )
                                                              }
                                                              sx={{
                                                                marginRight:
                                                                  "4px",
                                                                marginBottom:
                                                                  "4px",
                                                              }}
                                                            />
                                                          )
                                                        )}
                                                      </Grid>
                                                      {index <
                                                        regions.length - 1 && (
                                                        <Divider
                                                          sx={{
                                                            border:
                                                              "0.5px solid black !important",
                                                            opacity: 1,
                                                            marginTop: "20px",
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  )
                                                )}
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={handleAddRegion}
                                                  sx={{ marginTop: "10px" }}
                                                  className="orangy"
                                                >
                                                  Add Region
                                                </Button>
                                              </>
                                            )}
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6} mt={2}>
                          {userdata &&
                            (userdata.company_level_permissions.includes(
                              "superadmin"
                            ) ||
                              userdata.plan_details.is_permanent === true) && (
                              <Grid item xs={12} md={12}>
                                <Paper sx={{ borderRadius: "10px" }}>
                                  <Accordion
                                    sx={{ borderRadius: "10px !important" }}
                                    expanded={linkValidityOpen}
                                    onChange={() =>
                                      setLinkValidityOpen(!linkValidityOpen)
                                    }
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="custom-link-content"
                                      id="custom-link-header"
                                      sx={{ borderRadius: "10px" }}
                                      className="bluey"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Link Validity
                                      </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                      <FormControl
                                        component="fieldset"
                                        error={
                                          touched.linkValidity &&
                                          Boolean(errors.linkValidity)
                                        }
                                      >
                                        <FormLabel component="legend">
                                          Select link validity:
                                        </FormLabel>
                                        <RadioGroup
                                          aria-label="linkValidity"
                                          name="linkValidity"
                                          value={values.linkValidity}
                                          onChange={(e) => {
                                            handleChange(e);
                                            if (e.target.value !== "custom") {
                                              setFieldValue("expiryDate", null);
                                            }
                                          }}
                                        >
                                          <FormControlLabel
                                            value="permanent"
                                            control={<Radio />}
                                            label="Permanent"
                                          />
                                          <FormControlLabel
                                            value="24h"
                                            control={<Radio />}
                                            label="24 Hours"
                                          />
                                          <FormControlLabel
                                            value="7d"
                                            control={<Radio />}
                                            label="7 Days"
                                          />
                                          <FormControlLabel
                                            value="custom"
                                            control={<Radio />}
                                            label="Custom"
                                          />
                                        </RadioGroup>
                                        {touched.linkValidity &&
                                          errors.linkValidity && (
                                            <FormHelperText>
                                              {errors.linkValidity}
                                            </FormHelperText>
                                          )}
                                        {values.linkValidity === "custom" && (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DateTimePicker
                                              label="Expiry Date & Time"
                                              value={values.expiryDate}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  "expiryDate",
                                                  value
                                                )
                                              }
                                              minDate={new Date()}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  fullWidth
                                                  name="expiryDate"
                                                  variant="outlined"
                                                  error={
                                                    touched.expiryDate &&
                                                    Boolean(errors.expiryDate)
                                                  }
                                                  helperText={
                                                    touched.expiryDate &&
                                                    errors.expiryDate
                                                  }
                                                  size="small"
                                                />
                                              )}
                                            />
                                            <Grid>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "#d32f2f",
                                                  paddingX: "20px",
                                                  paddingY: "5px",
                                                }}
                                              >
                                                {touched.expiryDate &&
                                                  errors.expiryDate}
                                              </Typography>
                                            </Grid>
                                          </LocalizationProvider>
                                        )}
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                            )}
                          {userdata &&
                            (userdata.company_level_permissions.includes(
                              "superadmin"
                            ) ||
                              userdata.plan_details.is_password_protected ===
                                true) && (
                              <Grid item xs={12} md={12} mt={2}>
                                <Paper sx={{ borderRadius: "10px" }}>
                                  <Accordion
                                    expanded={passwordProtectionOpen}
                                    onChange={() =>
                                      setPasswordProtection(
                                        !passwordProtectionOpen
                                      )
                                    }
                                    sx={{ borderRadius: "10px !important" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="custom-password-content"
                                      id="custom-password-header"
                                      sx={{ borderRadius: "10px" }}
                                      className="bluey"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Password Protection
                                      </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.enablePasswordProtection
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "enablePasswordProtection",
                                                e.target.checked
                                              );
                                              SetProtectederrorEmails({});
                                            }}
                                            name="enablePasswordProtection"
                                            color="primary"
                                          />
                                        }
                                        label="Password Protection"
                                      />
                                      {values.enablePasswordProtection && (
                                        <FieldArray name="emails">
                                          {(arrayHelpers) => (
                                            <Grid>
                                              {values.emails.map(
                                                (email, index) => (
                                                  <Grid
                                                    key={index}
                                                    className="email-field"
                                                    style={{
                                                      marginTop: "18px",
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <TextField
                                                      id={`emails.${index}`}
                                                      name={`emails.${index}`}
                                                      label={`Email ${
                                                        index + 1
                                                      }`}
                                                      variant="outlined"
                                                      value={email}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      error={Boolean(
                                                        Protectedemails
                                                          ?.emails?.[index] ||
                                                          (touched.emails?.[
                                                            index
                                                          ] &&
                                                            errors.emails?.[
                                                              index
                                                            ])
                                                      )}
                                                      helperText={
                                                        Protectedemails
                                                          ?.emails?.[index] ||
                                                        (touched.emails?.[
                                                          index
                                                        ] &&
                                                          errors.emails?.[
                                                            index
                                                          ])
                                                      }
                                                    />
                                                    {index > 0 && (
                                                      <IconButton
                                                        aria-label="remove"
                                                        onClick={() =>
                                                          arrayHelpers.remove(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <RemoveCircleOutlineIcon />
                                                      </IconButton>
                                                    )}
                                                    {index ===
                                                      values.emails.length -
                                                        1 && (
                                                      <IconButton
                                                        aria-label="add"
                                                        onClick={() =>
                                                          arrayHelpers.push("")
                                                        }
                                                      >
                                                        <AddCircleOutlineIcon />
                                                      </IconButton>
                                                    )}
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          )}
                                        </FieldArray>
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          {userdata &&
                            (userdata.company_level_permissions.includes(
                              "superadmin"
                            ) ||
                              userdata.plan_details.is_custom_domain === true) && (
                              <Grid item xs={12} md={12} >
                                <Paper sx={{ borderRadius: "10px" }}>
                                  <Accordion
                                    expanded={customDomainOpen}
                                    onChange={() =>
                                      setCustomDomainOpen(!customDomainOpen)
                                    }
                                    sx={{ borderRadius: "10px !important" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="custom-code-content"
                                      id="custom-code-header"
                                      sx={{ borderRadius: "10px" }}
                                      className="bluey"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Custom Domain
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel>Custom Domain</InputLabel>
                                        <Select
                                          name="customDomain"
                                          value={values.customDomain}
                                          onChange={handleChange}
                                          label="Custom Domain"
                                        >
                                          <MenuItem value="Default">
                                            Default
                                          </MenuItem>
                                          {domains.map((domain, index) => (
                                            <MenuItem
                                              key={index}
                                              value={domain.domain_name}
                                            >
                                              {domain.domain_name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                            )}
                        </Grid>


                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        style={{ marginTop: "8px" }}
                      >
                        <Grid>
                          <Button
                            variant="contained"
                            style={{ marginTop: "8px" }}
                            className="pinky"
                            onClick={AccordionClose}
                          >
                            Save Settings{" "}
                            <SettingsIcon
                              sx={{
                                animation: `${rotate} 2s linear infinite`,
                                marginLeft: "5px",
                              }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Collapse>

                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  style={{ marginTop: "8px" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "8px" }}
                    className="orangy"
                  >
                    Short It
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
      <Grid container spacing={2} padding={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ height: "calc(100vh - 120px)" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 800,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    URL TABLES
                  </Typography>
                </Grid>
                <Grid ml={2}>
                  {userdata &&
                    (userdata.company_level_permissions.includes(
                      "superadmin"
                    ) ||
                      userdata.plan_details.is_folder === true) && (
                      <FolderManagement
                        uid={user_uid}
                        dataFetchers={dataFetchers}
                      />
                    )}
                </Grid>
                <Grid ml={2}>
                  <Tooltip title="Filter Folders">
                    <IconButton
                      className="bluey"
                      onClick={handleFolderFilterOpen}
                    >
                      <FolderOpenIcon sx={{ color: "white" }} />
                    </IconButton>
                    <FolderFilterModal
                      open={folderFilterOpen}
                      onClose={handleFolderFilterClose}
                      folders={folders}
                      selectedFolders={selectedFolders}
                      onApply={handleFolderFilterApply}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid>
                  <TextField
                    label="Global Search"
                    variant="outlined"
                    size="small"
                    value={filters.search}
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                  />
                </Grid>
                <Grid sx={{ marginLeft: "15px" }}>
                  <Button
                    variant="contained"
                    className="orangy"
                    size="small"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: "grey", margin: "10px 0" }} />

            <Paper
              style={{
                overflowX: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer component={Paper} style={{ overflow: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < data.length
                          }
                          checked={
                            data.length > 0 && selected.length === data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Sno
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Short Code
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Custom Folder
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Short URL
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Original URL
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Link Type
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Expires In
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        IP
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Clicks
                      </TableCell>
                      <TableCell sx={{ fontWeight: 800, textAlign: "center" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      return (
                        <TableRow
                          key={row._id}
                          selected={isItemSelected}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "white" : "grey.200",
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleSelect(event, row._id)}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 600, textAlign: "center" }}
                          >
                            {++counter}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.short_code}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.custom_folder}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Link
                              href={row.short_url}
                              target="_blank"
                              rel="noopener"
                              style={{ textDecoration: "none" }}
                            >
                              {truncateUrl(row.short_url, 15)}
                            </Link>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Grid>
                              {role === "superadmin" && (
                                <Grid>{row.url_title}</Grid>
                              )}
                              <Grid>
                                <Link
                                  href={row.full_url}
                                  target="_blank"
                                  rel="noopener"
                                  style={{ textDecoration: "none" }}
                                >
                                  {truncateUrl(row.full_url, 20)}
                                </Link>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.link_type}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.link_validity === "Permanent"
                              ? "No expiration"
                              : calculateCountdown(row.link_validity)}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.created_ip}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.total_clicks}
                          </TableCell>
                          <TableCell>
                            <Box display="flex" gap={2} justifyContent="center">
                              <SpeedTwoToneIcon
                                color="primary"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() => handleStatsClick(row)}
                              />
                              <ShareIcon
                                color="primary"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() => handleShareClick(row)}
                              />
                              {role === "superadmin" && (
                                <>
                                  <EditIcon
                                    color="primary"
                                    style={{ fontSize: "20px" }}
                                  />
                                  <DeleteIcon
                                    color="error"
                                    style={{ fontSize: "20px" }}
                                  />
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalPages}
                color="primary"
                page={page}
                onChange={(e, value) => setPage(value)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "16px 0",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <ShareModal
        open={open}
        handleClose={handleClose}
        shortLink={shortLink}
        fullLink={fullLink}
        short_code={shortCode}
        qrDetails={qrDetails}
        loadData={loadData}
      />
      <StatsModal
        isModalOpen={statsopen}
        handleCloseModal={handlestatsClose}
        uid={statsuid}
      />
    </>
  );
};

export default ApplnxCreation;
