import React, { createContext, useContext, useState, } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../config";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(sessionStorage.getItem('token') || null);
  const isAuthenticated = !!authToken;

  const login = async (values) => {
    try {
      const response = await axios.post(`${BASE_URL}/login`, { values });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const register = async (values) => {
    try {
      const response = await axios.post(`${BASE_URL}/user`, { values });
      return response;
    } catch (error) {
      throw error;
    }
  };


  return (
    <AuthContext.Provider value={{ authToken, login, register, isAuthenticated, }}>
      {children}
    </AuthContext.Provider>
  );
};
