import React, { useState } from "react";
import { Typography, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { BASE_URL } from "../../../../config";

const CreationExplanation = () => {
  const baseUrl = `${BASE_URL}/apiv1/links/create_shorturl`;
  const [customFolderChecked, setCustomFolderChecked] = useState(false);
  const [customDomainChecked, setCustomDomainChecked] = useState(false);
  const [customPasswordChecked, setCustomPasswordChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setCustomFolderChecked(event.target.checked);
  };
  const handleDomainChange = (event) => {
    setCustomDomainChecked(event.target.checked);
  };
  const handlePasswordChange = (event) => {
    setCustomPasswordChecked(event.target.checked);
  };


  const buildUrl = () => {
    let url = `${baseUrl}?api_key="Your-Key"&api_secret="Your-Secret"&unique_signature="Your-Sign"&url="Url"`;
    if (customFolderChecked) {
      url += "&customFolder=\"Folder Name\"";
    }
    if (customDomainChecked) {
      url += "&customDomain=\"Enter Verified Domain\"";
    }
    if (customPasswordChecked) {
      url += "&enablePasswordProtection=\"true or false\"";
    }
    if (customPasswordChecked) {
      url += "&emails=\"Emails Separated By Comma\"";
    }
    return url;
  };

  return (
    <Grid>

      <Typography variant="body1" gutterBottom>
        <strong>Base URL:</strong> <span style={{color:"green"}}>{baseUrl}</span>
      </Typography>

      <Typography sx={{fontWeight:"600",color:"orange"}}>Base URL with Mandatory Query Params :-</Typography>
      <Typography variant="body1" gutterBottom>
      <span style={{color:"green"}}>{baseUrl}?api_key="Your-Key"&api_secret="Your-Secret"&unique_signature="Your-Sign"&url="Url"</span>
      </Typography>

      <Typography sx={{fontWeight:"600",color:"orange"}}>Base URL with Additional Query Params :-</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={customFolderChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="Custom Folder"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={customDomainChecked}
            onChange={handleDomainChange}
            color="primary"
          />
        }
        label="Custom Domain"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={customPasswordChecked}
            onChange={handlePasswordChange}
            color="primary"
          />
        }
        label="Password Protection"
      />

     
      <Typography variant="body1" gutterBottom>
      <span style={{color:"green"}}> {buildUrl()}</span>

      </Typography>
      

    </Grid>
  );
};

export default CreationExplanation;
