import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { ContentCopy, Delete, PowerSettingsNew } from "@mui/icons-material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Controller from "../../../Service/ApiController";
import { apiEndPoint } from "../../../Service/ApiConstant";

const AdminApiKeyGeneration = () => {
  const [userdata, setUserdata] = useState();
  const [open, setOpen] = useState(false);
  const [valueopen, setValueOpen] = useState(false);
  const [generatedKey, setGeneratedKey] = useState("");
  const [generatedSecret, setGeneratedSecret] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const uid = sessionStorage.getItem("userUid");
  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + uid,
        "GET"
      );
      if (result.success === true) {
        setUserdata(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Super Admin User", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleValueOpen = () => setValueOpen(true);
  const handleValueClose = () => setValueOpen(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/users/apiv1/api_creation`,
          {
            user_uid: uid,
            title: values.title,
            description: values.description,
          }
        );
        const { api_key, api_secret } = response.data;
        setGeneratedKey(api_key);
        setGeneratedSecret(api_secret);
        handleClose();
        handleValueOpen();
        resetForm();
        fetchUser();
      } catch (error) {
        const data = error.response.data.error || "Error generating API key";
        enqueueSnackbar(`${data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
  });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleActivateDeactivate = async (key) => {
    try {
      const newStatus = !key.is_active;
      await axios.patch(`${BASE_URL}/users/apiv1/api_key_toggle`, {
        user_uid: uid,
        api_key: key.api_key,
        is_active: newStatus,
      });
      enqueueSnackbar(
        `API key ${newStatus ? "activated" : "deactivated"} successfully`,
        {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      fetchUser();
    } catch (error) {
      const data =
        error.response.data.message || "Failed to update API key status";
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleDelete = async (key) => {
    try {
      await axios.delete(`${BASE_URL}/users/apiv1/api_key_delete`, {
        data: {
          user_uid: uid,
          api_key: key.api_key,
        },
      });
      enqueueSnackbar("API key deleted successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      fetchUser();
    } catch (error) {
      enqueueSnackbar("Failed to delete API key", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 800,
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            API KEY GENERATION AND INTEGRATION
          </Typography>
          <Divider style={{ backgroundColor: "grey", margin: "10px 0" }} />
        </Grid>
        {userdata && userdata.api_keys.length > 0 && (
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontWeight:"800"}}>Title</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>Description</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>Unique Signature</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>API Key</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>Status</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>Created At</TableCell>
                    <TableCell sx={{fontWeight:"800"}}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userdata.api_keys.map((key, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "white" : "grey.200",
                      }}
                    >
                      <TableCell>{key.title}</TableCell>
                      <TableCell>{key.description}</TableCell>
                      <TableCell>{userdata && userdata.unique_signature}</TableCell>
                      <TableCell>{key.api_key}</TableCell>
                      <TableCell>
                        {key.is_active ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell>
                        {new Date(key.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={key.is_active ? "Deactivate" : "Activate"}
                        >
                          <IconButton
                            onClick={() => handleActivateDeactivate(key)}
                          >
                            <PowerSettingsNew
                              color={key.is_active ? "primary" : "default"}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDelete(key)}>
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "8px" }}
          >
            <Button
              type="submit"
              variant="contained"
              className="orangy"
              style={{ marginTop: "8px" }}
              onClick={handleOpen}
            >
              Generate Key
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "16px",
            width: "400px",
          }}
        >
          <Typography
            variant="h6"
            style={{ marginBottom: "16px" }}
            textAlign={"center"}
          >
            Enter Title and Description
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="orandy"
                  type="submit"
                  style={{ marginTop: "8px" }}
                >
                  Create Key
                </Button>
                <Button
                  variant="outlined"
                  className="pinky"
                  onClick={() => {
                    handleClose();
                    formik.resetForm();
                  }}
                  style={{
                    marginTop: "8px",
                    marginLeft: "8px",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>

      {generatedKey && (
        <Modal open={valueopen} onClose={handleValueClose}>
          <Paper
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "16px",
              width: "400px",
            }}
          >
            <Typography
              variant="h6"
              style={{ marginBottom: "16px" }}
              textAlign={"center"}
            >
              Generated API Key and Secret
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="API Key"
                  value={generatedKey}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Copy to clipboard">
                        <IconButton
                          onClick={() => copyToClipboard(generatedKey)}
                        >
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="API Secret"
                  value={generatedSecret}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Copy to clipboard">
                        <IconButton
                          onClick={() => copyToClipboard(generatedSecret)}
                        >
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "600", color: "orange" }}>
                  Note :- Make sure To Copy The Api Key Only One Time Visible
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="pinky"
                  onClick={handleValueClose}
                  style={{ marginTop: "8px", color: "white" }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default AdminApiKeyGeneration;
