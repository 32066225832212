import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import lottie from "lottie-web";
import { URL } from "../../config";

const UserActivation = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const data = message || "Account Activated Successfully";
  const [counter, setCounter] = useState(5);
  const lottieContainer = useRef(null);

  // Display a confirmation dialog after countdown ends
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(timer);
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Lottie animation
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/ced0e40d-8b12-48b5-8f9c-2d21bbad39df/nwUwqZNm2l.json",
    });

    return () => animation.destroy();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "800", color: "green" }}
        >
          {data}
        </Typography>
        {counter > 0 ? (
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", marginTop: "5px" }}
          >
            Setting Your Account in {counter} seconds...
          </Typography>
        ) : (
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", marginTop: "5px" }}
          >
            Setup completed. <Link to={URL} target="_blank">Click here</Link> to login
          </Typography>
        )}
        <div ref={lottieContainer} style={{ height: 300 }} />
      </Paper>
    </div>
  );
};

export default UserActivation;
