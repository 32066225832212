import React, { useState } from "react";
import { Box, Button, Paper, Typography, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import CodeInput from "react-code-input";
import axios from "axios";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../Assets/Images/headerlogo.png";
import { apiEndPoint } from "../../Service/ApiConstant";
import { BASE_URL } from "../../config";
import { RotateSpinner } from "react-spinners-kit";

const CenteredOtpForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const { short_code } = useParams();
  const [loading, setLoading] = useState(false);
  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const email_id = values.email;
        setEmail(email_id);
        const response = await axios.post(
          `${BASE_URL}${apiEndPoint.VERIFY_PROTECT_EMAIL}${short_code}/${email_id}`
        );
        if (response.status === 200) {
          const data = response.data.message;
          enqueueSnackbar(`${data}`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          setLoading(false);
          setStep(2);
        } else {
          setLoading(false);
          enqueueSnackbar("Failed to send OTP", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        const data = error.response.data.message;
        enqueueSnackbar(`${data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
  });

  const handleChangeOtp = (value) => {
    setOtp(value);
  };

  const handleOtpSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const email_id = email;
    try {
      const response = await axios.post(`${BASE_URL}/verify_otp`, {
        otp,
        email_id,
      });
      if (response.status === 200) {
        setLoading(false);
        if (response.data.message) {
          window.location.replace(response.data.message);
        } else {
          enqueueSnackbar("Link Is Broken", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } else {
        setLoading(false);
        enqueueSnackbar("OTP verification failed", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      const data = error.response.data.message;
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const inputStyle = {
    fontFamily: "monospace",
    margin: "4px",
    MozAppearance: "textfield",
    width: "40px",
    borderRadius: "3px",
    fontSize: "32px",
    height: "45px",
    paddingLeft: "7px",
    backgroundColor: "white",
    color: "black",
    border: "1px solid lightgrey",
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" mb={2}>
            <img
              src={logo}
              alt="logo"
              style={{ width: 180, height: 60, marginRight: 8 }}
            />
          </Box>
          {step === 1 && (
            <Box
              component="form"
              onSubmit={emailFormik.handleSubmit}
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h6" mb={2}>
                Enter Email ID
              </Typography>
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                name="email"
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                onBlur={emailFormik.handleBlur}
                error={
                  emailFormik.touched.email && Boolean(emailFormik.errors.email)
                }
                helperText={
                  emailFormik.touched.email && emailFormik.errors.email
                }
                sx={{ mb: 2 }}
              />
              <Button type="submit" variant="contained" color="primary">
                Submit Email
              </Button>
            </Box>
          )}
          {step === 2 && (
            <Box
              component="form"
              onSubmit={handleOtpSubmit}
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h6" mb={2}>
                Enter OTP
              </Typography>
              <CodeInput
                name="otp"
                type="text"
                inputMode="numeric"
                fields={6}
                value={otp}
                onChange={handleChangeOtp}
                inputStyle={inputStyle}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={otp.length < 6}
                mt={4}
                sx={{ marginTop: "20px" }}
              >
                Submit OTP
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.75)",
              zIndex: 9998,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <RotateSpinner size={50} thickness={10} color="white" />
          </div>
        </div>
      )}
    </Box>
  );
};

export default CenteredOtpForm;
