import React, { useState, useEffect } from "react";
import {
  Grid,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

const FolderFilterModal = ({ open, onClose, folders, selectedFolders, onApply }) => {
  const [localSelectedFolders, setLocalSelectedFolders] = useState(selectedFolders);

  useEffect(() => {
    setLocalSelectedFolders(selectedFolders);
  }, [selectedFolders]);

  const handleCheckboxChange = (folderName) => {
    if (localSelectedFolders.includes(folderName)) {
      setLocalSelectedFolders([]);
    } else {
      setLocalSelectedFolders([folderName]);
    }
  };

  const handleApply = () => {
    onApply(localSelectedFolders);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Folders</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={localSelectedFolders.includes("All")}
              onChange={() => handleCheckboxChange("All")}
            />
          }
          label="All"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={localSelectedFolders.includes("Default")}
              onChange={() => handleCheckboxChange("Default")}
            />
          }
          label="Default"
        />
        {folders.map((folder) => (
          <FormControlLabel
            key={folder.folder_name}
            control={
              <Checkbox
                checked={localSelectedFolders.includes(folder.folder_name)}
                onChange={() => handleCheckboxChange(folder.folder_name)}
              />
            }
            label={folder.folder_name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApply} color="primary">Apply Filters</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FolderFilterModal;
