import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  Badge,
  Grid,
  Tooltip,
  Modal,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
  Notifications as NotificationsIcon,
  ArrowDownward as ArrowDownwardIcon,
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import io from "socket.io-client";
import logo from "../../../Assets/Images/applnx.png";
import UploadButton from "../../Buttons/ChatAttachmentUploadButton/Uploadbutton";
import DownloadButton from "../../Buttons/ChatAttachmentDownloadButton/DownloadButton";
import axios from "axios";
import { BASE_URL } from "../../../config";
import { useSnackbar } from "notistack";
import { SOCKET_URL } from "../../../config";
import Loader from "../../Modals/Loader";

const socket = io(SOCKET_URL, {
  path: "/api/user/v1/live_chat",
});

function AttendTickets({ support_email_id }) {
  const attender = sessionStorage.getItem("UserName");
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [unreadCounts, setUnreadCounts] = useState({});
  const [showScrollButton, setShowScrollButton] = useState(false);
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [ticketAttachments, setTicketAttachments] = useState({});
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [scale, setScale] = useState(1);


  const handleWheel = (event) => {
    event.preventDefault();
    if (event.deltaY < 0) {
      setScale((prev) => Math.min(prev + 0.1, 3));
    } else {
      setScale((prev) => Math.max(prev - 0.1, 0.5));
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    multiple: true,
  });

  useEffect(() => {
    socket.on("ImageattachmentData", ({ ticket_id, path, data }) => {
      setTicketAttachments((prev) => ({
        ...prev,
        [ticket_id]: {
          ...(prev[ticket_id] || {}),
          [path]: data,
        },
      }));
    });
    return () => {
      socket.off("ImageattachmentData");
    };
  }, []);

  const fetchAssignedTickets = useCallback(() => {
    socket.emit("fetchAssignedTickets", support_email_id);
  }, [support_email_id]);

  const handleNewMessage = useCallback(
    ({ ticket_id, message, sender, timestamp, attachment }) => {
      if (selectedTicket && selectedTicket.ticket_id === ticket_id) {
        setChat((prevChat) => [
          ...prevChat,
          { message, sender, timestamp, attachment },
        ]);
      } else {
        setUnreadCounts((prev) => ({
          ...prev,
          [ticket_id]: (prev[ticket_id] || 0) + 1,
        }));
      }
    },
    [selectedTicket]
  );

  const loadTicketChat = useCallback((ticket) => {
    if (ticket.status === "in-progress" || ticket.status === "open") {
      socket.emit("fetchIndividualTicket", {
        user_uid: ticket.user_uid,
        ticket_id: ticket.ticket_id,
      });
    } else {
      setChat([]);
    }
  }, []);

  const handleLoadTicket = useCallback(
    (ticket) => {
      setSelectedTicket(ticket);
      loadTicketChat(ticket);
      setUnreadCounts((prev) => ({ ...prev, [ticket.ticket_id]: 0 }));
    },
    [loadTicketChat]
  );

  const handleAttendTicket = useCallback(() => {
    if (selectedTicket && selectedTicket.status === "open") {
      socket.emit("attendTicket", {
        salesEmployee: attender,
        data: selectedTicket,
      });
    }
  }, [selectedTicket, attender]);

  const handleSendMessage = useCallback(() => {
    if (message.trim() && selectedTicket) {
      socket.emit("sendMessage", {
        data: selectedTicket,
        message: message.trim(),
        sender: "Sales Employee",
      });
      setMessage("");
    }
  }, [message, selectedTicket]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChatScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    }
  };

  const loadImageAttachments = useCallback(
    (chatMessages) => {
      chatMessages.forEach((message) => {
        if (
          message.attachment &&
          message.attachment.contentType.startsWith("image/") &&
          !ticketAttachments[message.attachment.path]
        ) {
          socket.emit("getImageAttachment", {
            ticket_id: message.ticket_id,
            path: message.attachment.path,
          });
        }
      });
    },
    [ticketAttachments]
  );

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0 && selectedTicket) {
      setIsUploading(true);
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("ticket_id", selectedTicket.ticket_id);
        formData.append("sender", "Sales Employee");
        try {
          const response = await axios.post(
            `${BASE_URL}/api/user/v1/live_chat/file_upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          enqueueSnackbar(`${file.name} uploaded successfully`, {
            variant: "success",
          });
          // Emit a socket event to notify about the new file
          socket.emit("newFileUploaded", {
            ticket_id: selectedTicket.ticket_id,
            file: response.data.file,
          });
        } catch (error) {
          console.error("Error uploading file:", error);
          enqueueSnackbar(`Failed to upload ${file.name}`, {
            variant: "error",
          });
        }
      }
      setIsUploading(false);
      setIsCompleted(true);
      setTimeout(() => {
        setIsCompleted(false);
        setShowFileUploadModal(false);
      }, 4000);

      setSelectedFiles([]);
    }
  };

  useEffect(() => {
    socket.on("ticketListUpdated", fetchAssignedTickets);
    socket.on("ticketStatusUpdated", (updatedTicket) => {
      fetchAssignedTickets();
      if (
        selectedTicket &&
        selectedTicket.ticket_id === updatedTicket.ticket_id
      ) {
        setSelectedTicket((prev) => ({ ...prev, ...updatedTicket }));
        loadTicketChat(updatedTicket);
      }
    });
    socket.on("newMessage", handleNewMessage);
    socket.on("assignedTicketsData", (data) => {
      setTickets(data);
    });
    socket.on("individualTicketData", (ticketData) => {
      if (ticketData) {
        setSelectedTicket(ticketData);
        const mappedChat = ticketData.chat.map((c) => ({
          message: c.message,
          sender: c.sender,
          timestamp: c.timestamp ? new Date(c.timestamp) : new Date(),
          attachment: c.attachment,
          ticket_id: ticketData.ticket_id,
        }));
        loadImageAttachments(mappedChat);
        setChat(ticketData.chat || []);
        scrollToBottom();
      }
    });

    fetchAssignedTickets();

    return () => {
      socket.off("ticketListUpdated");
      socket.off("ticketStatusUpdated");
      socket.off("newMessage");
      socket.off("assignedTicketsData");
      socket.off("individualTicketData");
    };
  }, [fetchAssignedTickets, handleNewMessage, loadTicketChat, selectedTicket]);

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const renderAttachment = (attachment, ticket_id) => {
    if (!attachment) return null;

    if (attachment.contentType.startsWith("image/")) {
      const imageData = ticketAttachments[ticket_id]?.[attachment.path];
      return imageData ? (
        <img
          src={`data:${attachment.contentType};base64,${imageData}`}
          alt={attachment.filename}
          style={{ maxWidth: "100%", maxHeight: "200px", cursor: "pointer" }}
          onClick={() =>
            setFullScreenImage({
              src: `data:${attachment.contentType};base64,${imageData}`,
              filename: attachment.filename,
            })
          }
        />
      ) : (
        <CircularProgress size={24} />
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          <Grid>
            <Typography variant="body2" fontWeight={"600"}>
              {attachment.filename}
            </Typography>
          </Grid>
          <Grid mt={0.2}>
            <DownloadButton attachment={attachment} socket={socket} />
          </Grid>
        </Box>
      );
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  return (
    <Box display="flex" height="76vh" mt={2} >
       <Loader timing={2000} /> 
      <Grid container spacing={2} sx={{ height: "100%" }} >
        <Grid item xs={4}>
          <Paper
            sx={{
              minHeight: "76vh",
              maxHeight: "76vh",
              display: "flex",
              flexDirection: "column",
              boxShadow: 3,
            }}
              className="bluey"
          >
            <Typography
              variant="h6"
              sx={{
                padding: 2,
                textAlign: "center",
                borderBottom: "3px solid #ddd",
                fontWeight: "600",
              }}
            >
              <span style={{background:"white",padding:"10px",borderRadius:"25px",fontSize:"16px"}}> Open Tickets Available - {tickets.length || 0}</span>
            
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                padding: 2,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "rgba(0,0,0,0.1)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "4px",
                },
              }}
            >
              <List>
                {tickets.map((ticket) => (
                  <ListItem
                    button
                    key={ticket.ticket_id}
                    onClick={() => handleLoadTicket(ticket)}
                    selected={
                      selectedTicket &&
                      selectedTicket.ticket_id === ticket.ticket_id
                    }
                    sx={{
                      background: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id
                        ? "white" 
                        : "white", 
                      "&.Mui-selected": {
                        background: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
                      },
                      "&.Mui-selected:hover": {
                        background: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
                      },
                      "&:hover": {
                        background: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id
                          ? "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))" 
                          : "white",
                      },
                      borderRadius:"20px",
                      marginTop:"5px"
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar >
                        {ticket.user_name ? ticket.user_name[0] : "U"}
                      </Avatar>
                    </ListItemAvatar>

                    <Grid  sx={{
                          color: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id
                            ? "white" 
                            : "black", 
                          "&.Mui-selected": {
                            color: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
                          },
                          "&.Mui-selected:hover": {
                            color: "white",
                          },
                          "&:hover": {
                            color: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id
                              ? "white" 
                              : "white",
                          },
                        }}>
                      {" "}
                      <ListItemText
  primary={ticket.user_name || "Unknown"}
  secondary={ticket.email_id}
  primaryTypographyProps={{
    sx: {
      color: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id ? "white" : "black",
      fontWeight: 800,
      fontSize: "14px"
    }
  }}
  secondaryTypographyProps={{
    sx: {
      color: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id ? "white" : "gray",
      fontSize: "12px" // You can adjust the font size for secondary text
    }
  }}
/>

                

                    <ListItemSecondaryAction>
                      <Tooltip title="Notification">
                        <Badge
                          badgeContent={unreadCounts[ticket.ticket_id] || 0}
                          color="error"
                          invisible={unreadCounts[ticket.ticket_id] === 0}
                          sx={{
                            marginRight: 1.5,
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          <NotificationsIcon  sx={{
                          color: selectedTicket && selectedTicket.ticket_id === ticket.ticket_id
                            ? "white" 
                            : "black", 
                        }} />
                        </Badge>
                      </Tooltip>
                    </ListItemSecondaryAction>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <Paper
            sx={{
              minHeight: "76vh",
              maxHeight: "76vh",
              display: "flex",
              flexDirection: "column",
              boxShadow: 3,
            }}
              className="bluey"
          >
            {selectedTicket ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    borderBottom: "1px solid #ddd",
                    color: "white",
                  }}
                >
                  <Avatar src={logo} alt="Support" sx={{ marginRight: 2 }} />
                  <Typography variant="h6" sx={{ flexGrow: 1 ,fontWeight:"600"}}>
                    Customer Support - {selectedTicket.user_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    Status: {selectedTicket.status}
                  </Typography>
                </Box>

                <Box
                  ref={chatContainerRef}
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    padding: 2,
                    borderBottom: "1px solid #ddd",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "4px",
                    },
                  }}
                  onScroll={handleChatScroll}
                >
                  {selectedTicket.status === "open" ? (
                    <>
                      <Typography variant="body1" sx={{ marginBottom: 2,color:"white",fontSize:"20px", }}>
                       <span style={{fontWeight:"600"}}> Query : </span> {selectedTicket.query}
                      </Typography>
                      <Button
                        variant="contained"
                        className="pinky"
                        onClick={handleAttendTicket}
                        sx={{ marginBottom: 2 }}
                      >
                        Attend Ticket
                      </Button>
                    </>
                  ) : selectedTicket.status === "closed" ? (
                    <Grid 
                    container 
                    justifyContent="center" 
                    alignItems="center" 
                  >
                    <Typography 
                      variant="body1" 
                      sx={{ marginTop: 20, color: "white", fontWeight: "600",fontSize:"24px" }}
                    >
                      This ticket is closed. Please select another ticket to proceed.
                    </Typography>
                  </Grid>
                  
                  ) : (
                    chat.map((c, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection:
                            c.sender === "Customer" ? "row" : "row-reverse",
                          marginBottom: 1.5,
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: "70%",
                            width: "fit-content",
                            backgroundColor:
                              c.sender === "Customer" ? "#f0f0f0" : "#d0f0c0",
                            padding: 1,
                            borderRadius: 2,
                            wordWrap: "break-word",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                          <Typography
                            sx={{
                              wordWrap: "break-word",
                              color: "black",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            {c.message}
                          </Typography>
                          {c.attachment &&
                            renderAttachment(
                              c.attachment,
                              selectedTicket.ticket_id
                            )}
                          <Typography
                            variant="caption"
                            sx={{
                              alignSelf: "flex-end",
                              color: "rgba(0, 0, 0, 0.6)",
                              marginTop: "4px",
                              fontWeight: "800",
                              fontSize: "10px",
                            }}
                          >
                            {new Date(c.timestamp).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  )}
                  <div ref={chatEndRef} />
                </Box>

                {selectedTicket.status !== "closed" && (
                  <Box sx={{ display: "flex", padding: 2 }}>
                    <TextField
                      sx={{
                        marginRight: "10px",
                        background: "white",
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && message) {
                          handleSendMessage();
                        }
                      }}
                    />

                    <IconButton
                      color="primary"
                      className="greeny"
                      onClick={handleSendMessage}
                      sx={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                    <Tooltip title="Attach File">
                      <IconButton
                        onClick={() => setShowFileUploadModal(true)}
                        className="orangy"
                        sx={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <AttachFileIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                    {showScrollButton && (
                      <IconButton
                        className="blacky"
                        onClick={scrollToBottom}
                        sx={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </>
            ) : (
              <Typography
                variant="h6"
                sx={{ padding: 3, textAlign: "center", color: "white" ,marginTop:"30px"}}
              >
                Select a ticket to view details.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={showFileUploadModal}
        onClose={() => {
          setShowFileUploadModal(false);
          setSelectedFiles([]);
        }}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            maxHeight: "70vh",
            overflow: "hidden",
          }}
        >
          {/* Upper Section */}
          <Box
            sx={{
              flexShrink: 0,
            }}
          >
            <Typography
              id="file-upload-modal"
              variant="h6"
              component="h2"
              mb={2}
              textAlign={"center"}
              fontWeight={"800"}
            >
              Upload Files
            </Typography>
            <Paper
              {...getRootProps()}
              sx={{
                p: 2,
                textAlign: "center",
                cursor: "pointer",
                border: "2px solid mediumvioletred",
                bgcolor: isDragActive ? "action.hover" : "background.paper",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                Drag & drop files here, or click to select files
              </Typography>
              <CloudUploadIcon
                sx={{ fontSize: 48, my: 2, color: "mediumvioletred" }}
              />
            </Paper>
          </Box>

          {/* Scrollable Chips Section */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              mt: 2,
              mb: 2,
            }}
          >
            {selectedFiles.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleRemoveFile(file)}
                sx={{ mb: 1 }}
              />
            ))}
          </Box>

          {/* Fixed Button Section */}
          <Box
            sx={{
              flexShrink: 0,
              mt: 2,
            }}
          >
            {" "}
            <UploadButton
              isUploading={isUploading}
              isCompleted={isCompleted}
              handleClick={handleFileUpload}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
      open={!!fullScreenImage}
      onClose={() => setFullScreenImage(null)}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <img
        src={fullScreenImage?.src}
        alt={fullScreenImage?.filename}
        onWheel={handleWheel}
        style={{
          maxWidth: "90%",
          maxHeight: "90%",
          transform: `scale(${scale})`,
          transition: "transform 0.2s ease",
        }}
      />
    </Modal>
    </Box>
  );
}

export default AttendTickets;
