import React, { useEffect } from "react";
import { Box, Button, TextField, Paper } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import { useSnackbar } from "notistack";
import Controller from "../../../../Service/ApiController";
import { apiEndPoint } from "../../../../Service/ApiConstant";

const RazorPayForm = ({
  amount,
  currencySymbol,
  id,
  onOrderComplete,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const user_id = sessionStorage.getItem("userUid");

  useEffect(() => {
    const loadRazorpayScript = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    };

    loadRazorpayScript();
  }, []);

  const handlePayment = (values) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
      amount: amount * 100,
      currency: "INR",
      name: values.name,
      description: "Test Transaction",
      handler: function (response) {
        const paymentReceipt = {
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
          amount: amount,
          currency: "INR",
        };

        axios
          .post(`${BASE_URL}/razorpay/capture_payment`, {
            paymentId: response.razorpay_payment_id,
            amount: amount,
          })
          .then((captureResponse) => {
            Controller.ApiController(
              {
                transactionId: response.razorpay_payment_id,
                full_details: captureResponse.data,
                amount: currencySymbol + amount,
                status: "completed",
                payment_type: "RazorPay",
                payment_id: id,
                user_id: user_id,
              },
              apiEndPoint.PAYMENT,
              "POST"
            );
            onSuccess();
            onOrderComplete({
              ...paymentReceipt,
              captureResponse: captureResponse.data,
            });
            enqueueSnackbar("Payment completed successfully!", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          })
          .catch((error) => {
            enqueueSnackbar(
              "Payment successful, but there was an issue recording the payment. Please contact support.",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }
            );
          });
      },
      prefill: {
        name: values.name,
        email: values.email,
        contact: values.contact,
      },
      notes: {
        address: values.address,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          enqueueSnackbar("Payment Cancelled", {
            variant: "info",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <Paper elevation={3} sx={{ padding: "2rem", width: "100%", margin: "0 auto" }}>
      <Formik
        initialValues={{ name: "", email: "", contact: "", address: "" }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          contact: Yup.string().required("Required"),
          address: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handlePayment(values);
          setSubmitting(false);
          resetForm(); // Reset form fields after submission
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                id="contact"
                name="contact"
                label="Contact"
                value={values.contact}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.contact && Boolean(errors.contact)}
                helperText={touched.contact && errors.contact}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                id="address"
                name="address"
                multiline
                rows={3}
                label="Address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
            </Box>
            <Button
              variant="contained"
              color="info"
              size="small"
              type="submit"
              disabled={isSubmitting}
            >
              PROCEED TO PAY {currencySymbol}
              {amount}
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default RazorPayForm;
