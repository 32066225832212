// Loader.js
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { RotateSpinner } from "react-spinners-kit";
import logo from "../../../src/Assets/Images/applnx.png";

const Loader = ({ timing }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, timing);

    return () => clearTimeout(timer);
  }, [timing]);

  if (!loading) return null;

  const loaderContent = (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999,
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt="Loading..."
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40px",
          height: "40px",
        }}
      />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RotateSpinner size={65} thickness={100} color="red" />
      </div>
    </div>
  );

  return createPortal(loaderContent, document.body);
};

export default Loader;
