import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import TemplateCreation from "./TemplateCreation";
import TemplateList from "./TemplateList";
import Loader from "../Modals/Loader";

const TemplateMain = ({ role }) => {
  const [tabValue, setTabValue] = useState(0);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setEditingTemplate(null);
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
    setTabValue(0); 
  };

  const handleTemplateUpdated = () => {
    setEditingTemplate(null);
    setTabValue(1); 
  };

  const availableTabs = [
    {
      label: editingTemplate ? "Edit Template" : "Create Template",
      component: (
        <TemplateCreation
          editingTemplate={editingTemplate}
          onTemplateUpdated={handleTemplateUpdated}
        />
      ),
    },
    {
      label: "Template List",
      component: <TemplateList onEditTemplate={handleEditTemplate} />,
    },
  ];

  return (
    <div>
      <Loader timing={2000} />
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
        centered
        TabIndicatorProps={{ style: { display: "none" } }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiTab-root": {
            borderRadius: "40px",
            padding: "12px 24px",
            minWidth: "150px",
            margin: "0 10px",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "800",
            backgroundColor: "grey",
            color: "white",
            transition: "background-color 0.3s ease",
          },
          "& .Mui-selected": {
            background:
              "linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0))",
            color: "white !important",
          },
        }}
      >
        {availableTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      <div>{availableTabs[tabValue] && availableTabs[tabValue].component}</div>
    </div>
  );
};

export default TemplateMain;