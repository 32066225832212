import React from "react";
import MasterRechargeHistory from "../../MasterRechargeHistory/MasterRechargeHistory";

const AdminRechargeHistory = () => {
  const user_uid = sessionStorage.getItem("userUid");
  const role = sessionStorage.getItem("Role");
  return (
    <div>
      <MasterRechargeHistory uid={user_uid} role={role} />
    </div>
  );
};

export default AdminRechargeHistory;
