// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Components/Login/Login';
import { apiEndPoint } from './Service/ApiConstant';
import Controller from './Service/ApiController';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import MasterRoute from './Components/PrivateRoute/MasterRoute';
import { useSnackbar } from "notistack";
import Admin from './Components/Admin/Admin';
import CenteredOtpForm from './Components/ExternalPages/CenteredOtpForm';
import BrokenLinks from './Components/ExternalPages/BrokenLinks';
import UserActivation from './Components/ExternalPages/UserActivation';
import ChatButton from './Components/LiveChat/ChatButton';



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/protected_link/:short_code" element={<CenteredOtpForm />} />
        <Route path="/broken_links/:data" element={<BrokenLinks />} />
        <Route path="/broken_links" element={<BrokenLinks />} />
        <Route path="/account_activation" element={<UserActivation />} />
        <Route path="/application/master" element={<><AdminWithRoleCheck /></>} />
      </Routes>
    </Router>
  );
};

const AdminWithRoleCheck = () => {
  const [userRole, setUserRole] = useState(null);
  const [email_id, setEmail_id] = useState(null);
  const uid = sessionStorage.getItem("userUid");
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email_id");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    const fetchAction = async () => {
      try {
        const result = await Controller.ApiController(
          "",
          apiEndPoint.USER_ROLE_FETCHER + uid,
          "GET",
          {
            params: {
            },
          }
        );
        if (result.success === true) {
          const { email_id, user_name, user_uid } = result.data;
          sessionStorage.setItem("email_id", email_id);
          sessionStorage.setItem("UserName", user_name);
          sessionStorage.setItem("userUid", user_uid);
          const companyLevelPermissions = result.data.company_level_permissions || [];
          const firstPermission = companyLevelPermissions.length > 0 ? companyLevelPermissions[0] : null;
          setUserRole(firstPermission);
          setEmail_id(email_id)
          sessionStorage.setItem("Role", firstPermission);
        } else {
          enqueueSnackbar(`${result.data}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
      }
    };

    if (token) {
      fetchAction();
    }
  }, [uid, token, email,]);


  if (token && userRole === null) {
    return navigate("/");;
  }

  return (
    <MasterRoute redirectToPath="/">
      <Admin role={userRole} support_email_id={email_id} />
      {userRole === "admin" && <ChatButton />}
    </MasterRoute>
  );
};

export default App;
