// Payment.js
import React from "react";
import { Box,Paper } from "@mui/material";
import RazorPay from "./RazorPay";

const RazorPayment = ({ amount, currencySymbol, id, onSuccess }) => {
  const handleRazorpaySuccess = (response) => {
    console.log("Razorpay Payment Success");
  };
  const amountAsInt = parseInt(amount, 10);

  return (
    <Box sx={{ textAlign: "center", padding: "2rem",width:"100%" }}>
       
      <RazorPay
        amount={amountAsInt}
        currencySymbol={currencySymbol}
        id={id}
        onOrderComplete={handleRazorpaySuccess}
        onSuccess={onSuccess}
      />

    </Box>
  );
};

export default RazorPayment;
