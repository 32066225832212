import React from 'react'
import ApplnxCreation from '../../MasterApplnxCreation/ApplnxCreation'

const SuperAdminApplnxCreation = ({role}) => {
    const uid = sessionStorage.getItem("userUid");
  return (
    <div><ApplnxCreation role={role} uid={uid} /></div>
  )
}

export default SuperAdminApplnxCreation