import React from "react";
import { Grid, Paper, Typography, List, ListItem, ListItemText } from "@mui/material";
import SwaggerComponent from "./Swagger";
import CreationExplanation from "./ExplanationBlocks/CreationExplanation";

const routes = [
  { name: "Create ShortenUrl", path: "/links/apiv1/api_creation" },
  { name: "Get User Credits", path: "/users/apiv1/get_user/credits" },
];

const AdminApiDoc = () => {
  const [selectedRoute, setSelectedRoute] = React.useState(routes[0].path);

  const renderExplanation = () => {
    switch (selectedRoute) {
      case "/links/apiv1/api_creation":
        return <CreationExplanation />;
      case "/users/apiv1/get_user/credits":
        return (
          <Typography variant="body1">
            This API allows you to retrieve the credit balance for a specific user.
          </Typography>
        );
      default:
        return <Typography variant="body1">No explanation available.</Typography>;
    }
  };

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12} sm={1.5}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <List>
            {routes.map((route, index) => (
              <ListItem
                button
                key={index}
                selected={selectedRoute === route.path}
                onClick={() => setSelectedRoute(route.path)}
              >
                <ListItemText primary={route.name} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <SwaggerComponent />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={4.5}>
        <Paper elevation={3} style={{ padding: "20px", wordWrap: "break-word", overflow: "auto" }}>
          <Typography variant="h6" sx={{ fontWeight: "800" }}>Explanation :-</Typography>
          {renderExplanation()}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminApiDoc;
