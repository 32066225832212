import React, { useState } from "react";
import {
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../PrivateRoute/AuthContext";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../../Assets/Images/headerlogo.png";
import logo2 from "../../Assets/Images/applnx.png";
import { RotateSpinner } from "react-spinners-kit";

const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 1000px;
  max-width: 100%;
  min-height: 600px;
`;

const SignUpContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  ${(props) =>
    props.signingIn !== true
      ? `
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  `
      : null}
`;

const SignInContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(100%);` : null}
`;

const Forms = styled(Form)`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

const Title = styled.h5`
  font-weight: bold;
  margin: 0;
`;

const Button = styled.button`
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
`;

const GhostButton = styled(Button)`
  background-color: transparent;
  border-color: #ffffff;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(-100%);` : null}
`;

const Overlay = styled.div`
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(50%);` : null}
`;

const OverlayPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
`;

const ErrorModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;


const LeftOverlayPanel = styled(OverlayPanel)`
  transform: translateX(-20%);
  ${(props) => (props.signingIn !== true ? `transform: translateX(0);` : null)}
`;

const RightOverlayPanel = styled(OverlayPanel)`
  right: 0;
  transform: translateX(0);
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(20%);` : null}
`;

const CustomTextField = ({ field, form, type, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      fullWidth
      variant="outlined"
      margin="normal"
      size="small"
      type={type === "password" && showPassword ? "text" : type}
      error={form.touched[field.name] && Boolean(form.errors[field.name])}
      helperText={form.touched[field.name] && form.errors[field.name]}
      InputProps={
        type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

const Login = () => {
  const [signIn, toggle] = React.useState(true);
  const signUpFormikRef = React.useRef(null);
  const signInFormikRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const { register, login } = useAuth();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const signUpValidationSchema = Yup.object().shape({
    user_name: Yup.string()
      .required("Name is required")
      .matches(/^[A-Za-z\s]+$/, "Only letters and spaces are allowed"),
    company_name: Yup.string()
      .required("Organization is required")
      .matches(/^[A-Za-z\s]+$/, "Only letters and spaces are allowed"),
    email_id: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and at least 8 characters long"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const signInValidationSchema = Yup.object().shape({
    email_id: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      if (signIn) {
        const result = await login(values);
        setLoading(false);
        setSubmitting(false);
        resetForm();
        if(result.status === 200) {
          setLoading(false);
          const { token, companyPermissions, email_id, userName, user_uid } =
            result.data;
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("email_id", email_id);
          sessionStorage.setItem("UserName", userName);
          sessionStorage.setItem("userUid", user_uid);
          navigate(`application/master`);
        } else {
          setLoading(false);
          enqueueSnackbar(`${result.data}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } else {
        const result = await register(values);
        setLoading(false);
        setSubmitting(false);
        resetForm();
        if (result.status === 200) {
          setLoading(false);
          setIsModalOpen(true);
        }
      }
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      const data = error.response?.data?.message || "An error occurred";
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
    toggle(true);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Container>
          <SignUpContainer signingIn={signIn}>
            <Formik
              innerRef={signUpFormikRef}
              initialValues={{
                user_name: "",
                email_id: "",
                password: "",
                confirmPassword: "",
                company_name: "",
              }}
              validationSchema={signUpValidationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Forms>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid style={{ marginTop: "10px" }}>
                      <img
                        src={logo}
                        alt="icon"
                        className="profile"
                        style={{ height: "40px" }}
                      />
                    </Grid>
                    <Grid>
                      <Title>Create Account</Title>
                    </Grid>
                  </Grid>
                  <Field
                    name="user_name"
                    component={CustomTextField}
                    label="Name"
                  />
                  <Field
                    name="email_id"
                    component={CustomTextField}
                    label="Email"
                  />
                  <Field
                    name="company_name"
                    component={CustomTextField}
                    label="Organization Name"
                  />
                  <Field
                    name="password"
                    component={CustomTextField}
                    label="Password"
                    type="password"
                  />
                  <Field
                    name="confirmPassword"
                    component={CustomTextField}
                    label="Confirm Password"
                    type="password"
                  />
                  <Button type="submit">Sign Up</Button>
                </Forms>
              )}
            </Formik>
          </SignUpContainer>
          <SignInContainer signingIn={signIn}>
            <Formik
              innerRef={signInFormikRef}
              initialValues={{ email_id: "", password: "" }}
              validationSchema={signInValidationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Forms>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid style={{ marginTop: "10px" }}>
                      <img
                        src={logo}
                        alt="icon"
                        className="profile"
                        style={{ height: "40px" }}
                      />
                    </Grid>
                    <Grid>
                      <Title>Sign in</Title>
                    </Grid>
                  </Grid>

                  <Field
                    name="email_id"
                    component={CustomTextField}
                    label="Email"
                  />
                  <Field
                    name="password"
                    component={CustomTextField}
                    label="Password"
                    type="password"
                  />
                  <Button type="submit">Sign In</Button>
                </Forms>
              )}
            </Formik>
          </SignInContainer>
          <OverlayContainer signingIn={signIn}>
            <Overlay signingIn={signIn}>
              <LeftOverlayPanel signingIn={signIn}>
                <Title>Welcome Back!</Title>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <GhostButton
                  onClick={() => {
                    toggle(true);
                    if (signUpFormikRef.current) {
                      signUpFormikRef.current.resetForm();
                    }
                  }}
                >
                  Sign In
                </GhostButton>
              </LeftOverlayPanel>
              <RightOverlayPanel signingIn={signIn}>
              <Title style={{ padding: "10px" }}>
                  The fastest growing Link Shortner and QR Code Generator
                </Title>
                <Title style={{ padding: "10px" }}>
                  Free credits on Sign up here...!
                </Title>
                <GhostButton
                  onClick={() => {
                    toggle(false);
                    if (signInFormikRef.current) {
                      signInFormikRef.current.resetForm();
                    }
                  }}
                >
                  Sign Up
                </GhostButton>
              </RightOverlayPanel>
            </Overlay>
          </OverlayContainer>
        </Container>

        {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <img
            src={logo2}
            alt=".."
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "40px",
            }}
          />
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <RotateSpinner size={65} thickness={100} color="red" />
          </div>
        </div>
      )}

        <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="logout-confirmation-dialog-title"
          aria-describedby="logout-confirmation-dialog-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: 20,
              width: isSmallScreen ? "80%" : "auto",
              maxHeight: "90vh",
              overflow: "auto",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid>
                <Typography variant="h4">Message</Typography>
              </Grid>
              <Grid mt={2}>
                <Typography variant="body1">
                Thanks for Signing up..!!  Try out applnx.io for  <span style={{color:"green"}}>3Days</span> Free of charge. Enjoy the complimentary unlimited basic link generation...!
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body1" sx={{marginTop:"5px",fontSize:"16px",fontWeight:"800"}}> Kindly Activate Your Account By Clicking The Activation Link That Has Been Sent To Your Respective Email ID</Typography>
              </Grid>
              <Grid mt={2}>
                <Button onClick={closeModal}>OK</Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      </Grid>
    </>
  );
};

export default Login;